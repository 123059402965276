import { parsePhoneNumber } from "awesome-phonenumber";
export const nameValidation = (name) => {
    const re = /[^\d ]{1,100}$/;
    return re.test(String(name).trim());
};
// house number (only numbers, no letters, no spaces before and after, max 5 digits)
export const onlyDigitsMaxLength = (digits, maxLength = 5) => {
    const _digits = String(digits || "");
    if (_digits.length > maxLength)
        return false;
    const re = /^\d+$/g;
    return re.test(_digits);
};
// Post code validation based on country
export const postCodeValidation = (pCode, country, isCheckout = false) => {
    const code = String(pCode || "");
    let regex;
    switch (country) {
        // 4 digits, all between [0-9]
        case "AT":
        case "LU":
            regex = /^[0-9]{4}$/g;
            break;
        // 4 digits with the first between [1-9] and the rest [0-9]
        case "BE":
            regex = /^[1-9][0-9]{3}$/g;
            break;
        // 4 digits + 2 characters with possible space in the middle (checkout page), with the first digit [1-9] and the rest [0-9]
        case "NL":
            regex = isCheckout ? /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/g : /^[1-9][0-9]{3}[a-zA-Z]{2}$/g;
            break;
        // 5 digits, all between [0-9]
        case "DE":
        case "FR":
            regex = /^[0-9]{5}$/g;
            break;
        // 5 digits, all between [0-9], except if starting with 35, 38 or equal to 49581
        // Refer to https://fyayc-am.atlassian.net/browse/JOGGGAR-541?focusedCommentId=14404 for more info
        case "ES": {
            regex = /^(?!49581|3[58])[0-9]{5}$/g;
            break;
        }
        default:
            regex = /^\d+$/g;
    }
    return regex.test(code);
};
// street name and city (max 100 characters)
export const checkLength = (characters, maxLength = 100) => {
    const _characters = String(characters || "");
    return !(_characters.length > maxLength || _characters.length === 0);
};
export const phoneValidation = (_phone, isRequired = false, allowedCountries = []) => {
    const phone = String(_phone || "");
    if (phone === "")
        return !isRequired;
    if (allowedCountries.length) {
        const validPhoneNumber = allowedCountries.find((countryCode) => {
            const awesomePN = parsePhoneNumber(phone, {
                regionCode: countryCode,
            });
            return awesomePN.valid;
        });
        return !!validPhoneNumber;
    }
    const re = /^[0]\d+[0-9]$/g;
    return re.test(phone);
};
export const validateEmail = (email, emailDomainBlackList, emailEndingWhiteList) => {
    // Format Validation
    // eslint-disable-next-line max-len
    const emailFormatRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidFormat = emailFormatRegEx.test(String(email || "").toLowerCase());
    // Vendor domain Validation
    const blackListParsed = emailDomainBlackList.join("|");
    const vendorValidationRegEx = RegExp(`^.*@(${blackListParsed})\\..+$`);
    const isValidVendor = !vendorValidationRegEx.test(String(email || "").toLowerCase());
    // Domain ending (aka Top Level Domain) Validation
    const endingWhiteListParsed = emailEndingWhiteList.join("|");
    const emailEndingValidationRegEx = RegExp(`^.+@.+\.(${endingWhiteListParsed})$`);
    const isValidEnding = emailEndingValidationRegEx.test(String(email || "").toLowerCase());
    return isValidFormat && isValidVendor && isValidEnding;
};
// at least 6 symbols no space
export const passwordValidation = (_password) => {
    const password = String(_password);
    if (password.length < 6)
        return;
    const re = /^\S+$/g;
    return re.test(password);
};
// zipcode (only numbers, 1000-9999, no space before and after)
export const onlyDigitsWithExactLength = (_zipCode, digits = 4) => {
    const zipCode = String(_zipCode);
    if (zipCode.length !== digits)
        return false;
    const re = /^\d+$/g;
    return re.test(zipCode);
};
export const dhlNlZipValidation = (_zipCode) => {
    const zipCode = String(_zipCode);
    if (zipCode.length < 6 || zipCode.length > 7)
        return false;
    const re = /^\d{4}\s?[a-zA-Z]{2}$/g;
    return re.test(zipCode);
};
export const validateBirthDate = (birthDate = "", isRequired = false) => {
    if (birthDate === "" && !isRequired)
        return true;
    if (birthDate.length !== 10)
        return;
    const re = /(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/g;
    return re.test(birthDate);
};
