import { NewsForm } from "@/components";
import { useSocial } from "@/hooks/useSocial";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { ChevronDown, Container, Link, NextImage, Select, SelectContent, SelectIcon, SelectItem, SelectTrigger, SelectValue, useI18n, } from "@jog/react-components";
import { getCountryNameByCode } from "@jog/shared";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
const Hr = ({ className }) => (<hr className={classNames(className, "my-10 w-full border-t border-gray-silver")}/>);
const CountrySwitcher = ({ className }) => {
    var _a, _b, _c;
    const layout = useLayout();
    const land = (_c = (_b = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.footer) === null || _b === void 0 ? void 0 : _b.columns) === null || _c === void 0 ? void 0 : _c.find((i) => i.title === "Land");
    const country = process.env.NEXT_PUBLIC_COUNTRY;
    // For .com → country selector France (most visitors on .com are coming from France)
    const [value, setValue] = useState(country === "US" ? "FR" : country);
    const { $t } = useI18n();
    if (!land)
        return <></>;
    return (<div className={classNames(className, "flex justify-between lg:block")}>
            <h4 className="mb-0 mt-2 font-primary text-sm font-medium uppercase text-gray-dracula lg:mt-0 lg:mb-3.75">
                {land.title}
            </h4>
            <div className="relative inline-block">
                <Select value={value} onValueChange={(value) => {
            var _a;
            setValue(value);
            const url = (_a = land.items.find((i) => i.text === value)) === null || _a === void 0 ? void 0 : _a.url;
            if (url) {
                window.location.href = url;
            }
        }}>
                    <SelectTrigger className="block w-full appearance-none rounded-lg border-none py-2 pr-7 text-sm focus:outline-none">
                        <SelectValue>{$t(getCountryNameByCode(value))}</SelectValue>
                        <SelectIcon asChild>
                            <ChevronDown className="size-4 group pointer-events-none absolute right-0 top-1/2 -translate-y-1/2 text-black" aria-hidden="true" strokeWidth={2}/>
                        </SelectIcon>
                    </SelectTrigger>
                    <SelectContent position="popper" className="relative z-50 overflow-hidden border border-gray-medium bg-white text-black data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1">
                        {land.items.map((field, index) => (<SelectItem className="relative flex w-full cursor-pointer select-none items-center py-1.5 pl-2 pr-8 text-sm outline-none focus:bg-gray-bg" key={index} value={field.text}>
                                {$t(getCountryNameByCode(field.text.toUpperCase()))}
                            </SelectItem>))}
                    </SelectContent>
                </Select>
            </div>
        </div>);
};
const Newsletter = ({ className }) => {
    const { $t } = useI18n();
    return (<div className={classNames(className, "flex w-full flex-wrap items-center lg:w-auto lg:flex-nowrap")}>
            <div className="mr-8 hidden font-primary text-13 font-medium uppercase text-black lg:block">
                {$t("Subscribe newsletter")}
            </div>
            <div className="block font-primary text-gray-base lg:hidden">
                <h4 className="text-sm font-medium uppercase text-gray-dracula">{$t("Keep in touch")}</h4>
                <p className="mt-1 text-13">{$t("Sign up for our newsletter now and receive a discount!")}</p>
            </div>
            <NewsForm source="footer" className="mt-2 lg:mt-0"/>
        </div>);
};
const Social = ({ className }) => {
    const socials = useSocial();
    const { $t } = useI18n();
    return (<div className={classNames(className)}>
            <div className="mb-4 block lg:hidden">
                <h4 className="text-xs font-medium uppercase">{$t("More inspiration")}</h4>
            </div>
            <ul className="-mb-1 -mr-5 flex items-center lg:-mr-8 lg:mb-0 lg:h-full">
                {socials.map((item, index) => (<li key={index} className="mb-1 mr-5 lg:mb-0 lg:mr-8">
                        <Link href={item.href} title="" target="_blank" rel="noopener" className="decoration-0">
                            <NextImage blur={false} src={item.image} alt="Social network icon" width={20} height={20}/>
                        </Link>
                    </li>))}
            </ul>
        </div>);
};
const Nav = ({ nav }) => {
    const [fold, setFold] = useState(true);
    return (<AnimatePresence mode="sync">
            <div>
                <h4 className="mb-0 mt-2 flex w-full items-center justify-between font-primary text-sm font-medium uppercase text-gray-dracula lg:mt-0 lg:mb-3.75">
                    <Link href={nav.url}>{nav.title}</Link>
                    <div className="flex h-6 items-center pl-8 lg:hidden" onClick={() => setFold((v) => !v)}>
                        <motion.span transition={{ duration: 0.3, ease: [0.55, 0.055, 0.675, 0.19] }} animate={fold ? "close" : "open"} variants={{ open: { rotate: "180deg" }, close: { rotate: "0deg" } }}>
                            <ChevronDown strokeWidth={2}/>
                        </motion.span>
                    </div>
                </h4>
                <motion.ul transition={{ duration: 0.3, ease: [0.55, 0.055, 0.675, 0.19] }} animate={fold ? "close" : "open"} variants={{ open: { height: "auto" }, close: { height: 0 } }} className="overflow-hidden lg:!h-auto">
                    {nav.items.map((item, index) => (<li className="py-2 text-sm" key={index}>
                            <Link className="hover:underline" href={item.url}>
                                {item.text}
                            </Link>
                        </li>))}
                </motion.ul>
            </div>
        </AnimatePresence>);
};
const Navs = ({ className }) => {
    var _a;
    const layout = useLayout();
    const footer = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.footer;
    if (!footer)
        return <div className={classNames(className, "w-full bg-white py-10")}/>;
    return (<div className={classNames(className, "grid w-full grid-cols-1 gap-x-3 gap-y-3 lg:grid-cols-4")}>
            {footer.columns
            .filter((i) => i.title !== "Land")
            .map((nav, index) => (<Nav key={index} nav={nav}/>))}
            <CountrySwitcher />
        </div>);
};
const Payments = ({ className }) => {
    var _a, _b;
    const { layout } = useLayout();
    const logos = (_b = (_a = layout === null || layout === void 0 ? void 0 : layout.footer) === null || _a === void 0 ? void 0 : _a.logos) === null || _b === void 0 ? void 0 : _b.flat();
    const { $t } = useI18n();
    if (!logos)
        return <></>;
    return (<div className={className}>
            <div className="mb-4 block lg:hidden">
                <h4 className="text-xs font-medium uppercase">{$t("payment options")}</h4>
            </div>
            <ul className="-mb-4 -mr-6 flex flex-wrap items-center">
                {logos.map((logo, index) => (<li key={index} className="mb-4 mr-6">
                        <NextImage blur={false} src={logo.src} alt="Footer logo" width={(logo.width / logo.height) * 20} height={20}/>
                    </li>))}
            </ul>
        </div>);
};
const Legals = ({ className }) => {
    var _a, _b;
    const { layout } = useLayout();
    const legals = (_b = (_a = layout === null || layout === void 0 ? void 0 : layout.footer) === null || _a === void 0 ? void 0 : _a.legal) === null || _b === void 0 ? void 0 : _b.flat();
    if (!legals)
        return <></>;
    return (<div className={className}>
            <ul className="-mb-2 -mr-7 flex flex-wrap">
                {legals.map((legal, index) => (<li className="mb-2 mr-7" key={index}>
                        <Link className="text-13" href={legal.url}>
                            {legal.label}
                        </Link>
                    </li>))}
            </ul>
        </div>);
};
export const Footer = ({ newsletter }) => {
    var _a;
    const layout = useLayout();
    const footer = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.footer;
    if (!footer)
        return <div className="w-full bg-white py-10"/>;
    return (<div className="mt-3 w-full bg-white py-10 px-4 lg:px-0">
            <Container className="overflow-hidden">
                <div className="flex flex-wrap justify-between">
                    {newsletter && (<>
                            <Hr className="order-1 lg:mb-16"/>
                            <Social className="order-9 w-full lg:order-2 lg:w-auto"/>
                            <Newsletter className="order-3"/>
                            <Hr className="order-4 lg:mt-16 "/>
                        </>)}
                    <Navs className="order-5 w-full lg:pb-48"/>
                    <Hr className="order-6 lg:hidden"/>
                    <Payments className="order-7 w-full lg:w-auto"/>
                    {newsletter && <Hr className="order-8 lg:hidden"/>}
                    <Hr className="order-10 lg:order-12"/>
                    <Legals className="order-11 w-full overflow-hidden lg:w-auto"/>
                </div>
            </Container>
        </div>);
};
