import { asText } from "@prismicio/helpers";
import { typedBoolean } from "../helper";
export const transPdp = (data) => {
    var _a, _b, _c;
    const mobile_sorting_by_name = data.mobile_sorting_by_name.length
        ? data.mobile_sorting_by_name
        : data.sorting_by_name;
    return {
        faslet: data.faslet_show,
        categoryKeys: data.category_keys.map((i) => i.category_key || "").filter(typedBoolean),
        variantItemCover: data.variant_item_cover,
        // socialProofText: data.social_proof_text,
        sizeConflictText: data.size_conflict_text,
        toolRecommend: data.tool_recommend,
        desktopSortingByName: ((_a = data.sorting_by_name) === null || _a === void 0 ? void 0 : _a.map((i) => i.name || "").filter(typedBoolean)) || [],
        mobileSortingByName: (mobile_sorting_by_name === null || mobile_sorting_by_name === void 0 ? void 0 : mobile_sorting_by_name.map((i) => i.name || "").filter(typedBoolean)) || [],
        sortingByQuality: ((_b = data.sorting_by_quality) === null || _b === void 0 ? void 0 : _b.map((i) => i.quality || "").filter(typedBoolean)) || [],
        attributes: ((_c = data.attributes) === null || _c === void 0 ? void 0 : _c.map(({ attributes, label, attribute_text, capitalize }) => ({
            attributes: asText(attributes) || "",
            label: asText(label) || "",
            attribute_text: asText(attribute_text) || "",
            capitalize: capitalize,
        }))) || [],
        discountLabel: data.discount_label,
        promoInformation: [...(data.body1 || [])].filter(typedBoolean).map(({ items, primary }) => ({
            color: primary.color || "auto",
            richTexts: items.map((i) => i.rich_text),
        })),
        sizeConflictTextBgColor: data.size_conflict_text_bg_color,
        sizeConflictTextColor: data.size_conflict_text_color,
        // socialProofTextColor: data.social_proof_text_color,
        checkmarkBox: [...(data.body || [])].filter(typedBoolean).map(({ items, primary }) => ({
            backgroundColor: primary.box_color || "",
            items: items.map((i) => asText(i.list_item)).filter(typedBoolean),
        })),
        sustainable: [...(data.body3 || [])]
            .filter(typedBoolean)
            .map(({ items, primary }) => ({ id: asText(primary.id) || "", items: items.map((i) => i.message) }))
            .filter((i) => i.id),
        reviewInfo: data.review_info || [],
        metaTagTitle: asText(data.meta_tag_title) || "",
        metaTagDescription: asText(data.meta_tag_description) || "",
        metaTagRobots: asText(data.meta_tag_robots) || "",
        ownSizeToolRecommend: data.own_size_tool_recommend,
    };
};
