import { RadioTabBase } from "@jog/react-components";
import React from "react";
const RadioTabWrapper = ({ children }) => (<div className="mb-7.5 border border-gray-medium p-4 pb-7.5 text-sm text-black">{children}</div>);
export const RadioTab = React.memo(RadioTabBase({
    RadioTabWrapper,
    tabHeaderWrapperCss: {
        ".tab-head__right-wrapper": {
            color: "#000",
            ".location-icon::before": {
                fontSize: "14px",
            },
        },
        ".jc-green": {
            color: "#000",
        },
    },
    distanceLabelColor: "#000",
}));
