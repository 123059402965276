import { gql } from "graphql-request";
export const allServiceQuery = gql `
    query allServiceQuery($lang: String!, $after: String) {
        content: allService_pages(lang: $lang, after: $after) {
            edges {
                node {
                    _meta {
                        id
                        type
                        lang
                        uid
                    }
                    url
                }
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;
