import { JC_FRIEND_SKU, getArticleNameFromTW, getGtmIdFromTW, transGtmId, typedBoolean } from "../helper";
import { adpageHook, gtmPush } from "./index";
export const gtmAdpageUserData = (adpage, type) => {
    gtmPush({
        event: "trytagging_user_data",
        page: { title: window.document.title, ...(type ? { type } : {}) },
        device: adpage.device,
        marketing: adpage.marketing,
    });
};
export const gtmAdpageEmail = (email) => {
    gtmPush({ email });
};
export const gtmAdpageItemList = (items) => {
    gtmPush({
        event: "trytagging_view_item_list",
        ecommerce: {
            items: items.map((i, index) => {
                var _a, _b, _c, _d, _e, _f;
                const category = ((_c = (_b = (_a = i.attributes
                    .find(({ name }) => name === "categoryAsUrl")) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.at(0)) === null || _c === void 0 ? void 0 : _c.split("/").filter(typedBoolean)) || [];
                category.reverse();
                const [item_category, item_category2, item_category3, item_category4, item_category5] = category;
                return {
                    item_id: getGtmIdFromTW(i),
                    item_name: i.title,
                    // item_sku: i,
                    item_list_id: (_e = (_d = i.attributes.find(({ name }) => name === "parent")) === null || _d === void 0 ? void 0 : _d.values) === null || _e === void 0 ? void 0 : _e.at(0),
                    item_list_name: (_f = getArticleNameFromTW(i)) === null || _f === void 0 ? void 0 : _f.articleName,
                    item_category,
                    item_category2,
                    item_category3,
                    item_category4,
                    item_category5,
                    index: index,
                    quantity: 1,
                    price: i.price,
                };
            }),
        },
    });
};
export const gtmAdpageItemSizeDetail = (pdpData, gtmId, categoryPath, sku, quantity = 1) => {
    var _a, _b, _c, _d, _e;
    const price = pdpData.price.centAmount / 100;
    const category = categoryPath.split("/").filter(typedBoolean);
    category.reverse();
    const [item_category, item_category2, item_category3, item_category4, item_category5] = category;
    const item_list_id = (_b = (_a = pdpData.attributes) === null || _a === void 0 ? void 0 : _a.find((item) => item.name === "parent")) === null || _b === void 0 ? void 0 : _b.value;
    const item_list_name = (_d = (_c = pdpData.attributes) === null || _c === void 0 ? void 0 : _c.find((item) => item.name === "articlename")) === null || _d === void 0 ? void 0 : _d.value;
    const item = {
        item_id: gtmId,
        item_name: (_e = pdpData.name) === null || _e === void 0 ? void 0 : _e.en,
        item_sku: sku,
        item_list_id,
        item_list_name,
        item_category,
        item_category2,
        item_category3,
        item_category4,
        item_category5,
        index: 0,
        quantity,
        price,
    };
    gtmPush({
        event: "trytagging_view_item_size",
        ecommerce: {
            value: item.quantity * item.price,
            currency: pdpData.price.currencyCode,
            items: [item],
        },
    });
};
export const gtmAdpageItemDetail = (pdpData, gtmId, categoryPath, quantity = 1) => {
    var _a, _b, _c, _d, _e;
    const price = pdpData.price.centAmount / 100;
    const category = categoryPath.split("/").filter(typedBoolean);
    category.reverse();
    const [item_category, item_category2, item_category3, item_category4, item_category5] = category;
    const item_list_id = (_b = (_a = pdpData.attributes) === null || _a === void 0 ? void 0 : _a.find((item) => item.name === "parent")) === null || _b === void 0 ? void 0 : _b.value;
    const item_list_name = (_d = (_c = pdpData.attributes) === null || _c === void 0 ? void 0 : _c.find((item) => item.name === "articlename")) === null || _d === void 0 ? void 0 : _d.value;
    const item = {
        item_id: gtmId,
        item_name: (_e = pdpData.name) === null || _e === void 0 ? void 0 : _e.en,
        item_list_id,
        item_list_name,
        item_category,
        item_category2,
        item_category3,
        item_category4,
        item_category5,
        index: 0,
        quantity,
        price,
    };
    gtmPush({
        event: "trytagging_view_item",
        ecommerce: {
            value: item.quantity * item.price,
            currency: pdpData.price.currencyCode,
            items: [item],
        },
    });
};
export const gtmAdpageAddToCart = (product, sku, gtmId, categoryPath) => {
    var _a, _b, _c, _d, _e;
    const price = product.price.centAmount / 100;
    const category = categoryPath.split("/").filter(typedBoolean);
    category.reverse();
    const [item_category, item_category2, item_category3, item_category4, item_category5] = category;
    const item_list_id = (_b = (_a = product.attributes) === null || _a === void 0 ? void 0 : _a.find((item) => item.name === "parent")) === null || _b === void 0 ? void 0 : _b.value;
    const item_list_name = (_d = (_c = product.attributes) === null || _c === void 0 ? void 0 : _c.find((item) => item.name === "articlename")) === null || _d === void 0 ? void 0 : _d.value;
    gtmPush({
        event: "trytagging_add_to_cart",
        ecommerce: {
            value: price,
            currency: product.price.currencyCode,
            items: [
                {
                    item_id: gtmId,
                    item_name: (_e = product.name) === null || _e === void 0 ? void 0 : _e.en,
                    item_sku: sku,
                    item_list_id,
                    item_list_name,
                    item_category,
                    item_category2,
                    item_category3,
                    item_category4,
                    item_category5,
                    index: 1,
                    quantity: 1,
                    price,
                },
            ],
        },
    });
};
const buildCart = (cart) => {
    const items = cart.lineItems
        .filter((i) => i.sku !== JC_FRIEND_SKU)
        .map((i, index) => {
        var _a;
        const category = i.categoryAsUrl.split("/").filter(typedBoolean);
        category.reverse();
        const [item_category, item_category2, item_category3, item_category4, item_category5] = category;
        return {
            item_id: transGtmId({ articleName: i.articleName, colorCode: i.colorCode }),
            item_name: (_a = i.name) === null || _a === void 0 ? void 0 : _a.en,
            item_sku: i.sku,
            item_list_id: i.parent,
            item_list_name: i.articleName,
            item_category,
            item_category2,
            item_category3,
            item_category4,
            item_category5,
            index,
            quantity: i.quantity,
            price: i.prices.firstPriceInTheCart.centAmount / 100,
        };
    });
    return {
        value: items.reduce((pre, { quantity, price }) => quantity * price + pre, 0),
        currency: cart.total.currencyCode,
        items,
    };
};
export const gtmAdpageRemoveFromCart = (item, quantity = 1) => {
    var _a;
    const price = item.prices.firstPriceInTheCart.centAmount / 100;
    const category = item.categoryAsUrl.split("/").filter(typedBoolean);
    category.reverse();
    const [item_category, item_category2, item_category3, item_category4, item_category5] = category;
    gtmPush({
        event: "trytagging_add_to_cart",
        ecommerce: {
            value: price * quantity,
            currency: item.prices.firstPriceInTheCart.currencyCode,
            items: [
                {
                    item_id: transGtmId({ articleName: item.articleName, colorCode: item.colorCode }),
                    item_name: (_a = item.name) === null || _a === void 0 ? void 0 : _a.en,
                    item_sku: item.sku,
                    item_list_id: item.parent,
                    item_list_name: item.articleName,
                    item_category,
                    item_category2,
                    item_category3,
                    item_category4,
                    item_category5,
                    index: 1,
                    quantity,
                    price,
                },
            ],
        },
    });
};
export const gtmAdpageBeginCheckout = (cart) => {
    var _a, _b;
    gtmPush({
        event: "trytagging_begin_checkout",
        ecommerce: { ...buildCart(cart), coupon: (_b = (_a = cart.vouchers) === null || _a === void 0 ? void 0 : _a.at(0)) === null || _b === void 0 ? void 0 : _b.code },
    });
};
export const gtmAdpageAddPaymentInfo = (cart, payment_type) => {
    var _a, _b;
    gtmPush({
        event: "trytagging_add_payment_info",
        ecommerce: { ...buildCart(cart), payment_type, coupon: (_b = (_a = cart.vouchers) === null || _a === void 0 ? void 0 : _a.at(0)) === null || _b === void 0 ? void 0 : _b.code },
    });
};
export const gtmAdpageAddShippingInfo = (cart, shipping_tier) => {
    var _a, _b;
    gtmPush({
        event: "trytagging_add_shipping_info",
        ecommerce: { ...buildCart(cart), shipping_tier, coupon: (_b = (_a = cart.vouchers) === null || _a === void 0 ? void 0 : _a.at(0)) === null || _b === void 0 ? void 0 : _b.code },
    });
};
export const gtmAdpagePurchase = (order, marketing) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    const items = order.lineItems
        .filter((i) => i.sku !== JC_FRIEND_SKU)
        .map((i, index) => {
        var _a;
        const category = i.categoryAsUrl.split("/").filter(typedBoolean);
        category.reverse();
        const [item_category, item_category2, item_category3, item_category4, item_category5] = category;
        return {
            item_id: transGtmId({ articleName: i.articleName, colorCode: i.colorCode }),
            item_name: (_a = i.name) === null || _a === void 0 ? void 0 : _a.en,
            item_sku: i.sku,
            item_list_id: i.parent,
            item_list_name: i.articleName,
            item_category,
            item_category2,
            item_category3,
            item_category4,
            item_category5,
            index,
            quantity: i.quantity,
            price: i.prices.firstPriceInTheCart.centAmount / 100,
        };
    });
    const body = {
        event: "trytagging_purchase",
        ecommerce: {
            tax: (((_a = order.totalTax) === null || _a === void 0 ? void 0 : _a.centAmount) || 0) / 100,
            coupon: (_c = (_b = order.vouchers) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.code,
            affiliation: process.env.NEXT_PUBLIC_AFFILIATION,
            shipping: (((_d = order.shippingCosts) === null || _d === void 0 ? void 0 : _d.costs.centAmount) || 0) / 100,
            transaction_id: order.orderNumber,
            value: order.total.centAmount / 100,
            currency: order.total.currencyCode,
            items,
        },
        user_data: {
            customer_id: order.customerId,
            billing_first_name: (_e = order.billingAddress) === null || _e === void 0 ? void 0 : _e.firstName,
            billing_last_name: (_f = order.billingAddress) === null || _f === void 0 ? void 0 : _f.lastName,
            billing_address: [(_g = order.billingAddress) === null || _g === void 0 ? void 0 : _g.streetName, (_h = order.billingAddress) === null || _h === void 0 ? void 0 : _h.additionalStreetInfo]
                .filter(typedBoolean)
                .join(" "),
            billing_postcode: (_j = order.billingAddress) === null || _j === void 0 ? void 0 : _j.postalCode,
            billing_country: (_k = order.billingAddress) === null || _k === void 0 ? void 0 : _k.country,
            billing_state: "",
            billing_city: (_l = order.billingAddress) === null || _l === void 0 ? void 0 : _l.city,
            billing_email: (_m = order.billingAddress) === null || _m === void 0 ? void 0 : _m.email,
            billing_phone: (_o = order.billingAddress) === null || _o === void 0 ? void 0 : _o.phone,
            shipping_first_name: (_p = order.shippingAddress) === null || _p === void 0 ? void 0 : _p.firstName,
            shipping_last_name: (_q = order.shippingAddress) === null || _q === void 0 ? void 0 : _q.lastName,
            shipping_company: "",
            shipping_address: [(_r = order.shippingAddress) === null || _r === void 0 ? void 0 : _r.streetName, (_s = order.shippingAddress) === null || _s === void 0 ? void 0 : _s.additionalStreetInfo]
                .filter(typedBoolean)
                .join(" "),
            shipping_postcode: (_t = order.shippingAddress) === null || _t === void 0 ? void 0 : _t.postalCode,
            shipping_country: (_u = order.shippingAddress) === null || _u === void 0 ? void 0 : _u.country,
            shipping_state: "",
            shipping_city: (_v = order.shippingAddress) === null || _v === void 0 ? void 0 : _v.city,
            shipping_phone: (_w = order.shippingAddress) === null || _w === void 0 ? void 0 : _w.phone,
            email: (_x = order.billingAddress) === null || _x === void 0 ? void 0 : _x.email,
            first_name: (_y = order.billingAddress) === null || _y === void 0 ? void 0 : _y.firstName,
            last_name: (_z = order.billingAddress) === null || _z === void 0 ? void 0 : _z.lastName,
            // new_customer: "false",
        },
        marketing,
    };
    gtmPush(body);
    (_0 = adpageHook("/order_created", body)) === null || _0 === void 0 ? void 0 : _0.catch((e) => {
        console.error(e);
    });
};
