import { asText } from "@prismicio/helpers";
import { parsePhoneNumber } from "awesome-phonenumber";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import Router from "next/router";
import { getPdp } from "./aws/commerce";
import { localStorage } from "./localStorage";
import { RedisCache } from "./redis/client";
dayjs.extend(isBetween);
export const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
const addZeroIfCentsAreLessThanTen = (cents) => {
    if (cents < 10)
        return "0" + cents;
    return cents;
};
export const convertCentsToEuros = (cents) => {
    cents = Math.round(Number(cents)) || 0;
    const euros = Math.floor(cents / 100);
    const remainingCents = addZeroIfCentsAreLessThanTen(cents % 100);
    if (remainingCents === 0) {
        return `${euros}`;
    }
    else {
        return `${euros}.${remainingCents}`;
    }
};
export const typedBoolean = (value) => Boolean(value);
export const parsePhoneNumberByCountryCode = (phone, country) => {
    var _a;
    if (!phone)
        return "";
    const phoneNumber = parsePhoneNumber(phone, { regionCode: country });
    return phoneNumber.valid ? (_a = phoneNumber.number.national) === null || _a === void 0 ? void 0 : _a.replace(/-|\s/g, "") : "";
};
export const navigate = (pathname, search, query) => {
    if (typeof window !== "undefined") {
        Router.push({ pathname, search, query }).catch(() => { });
    }
};
const ERROR_MESSAGE_MAP = {
    cancel: "You have completed the payment process",
    reject: "The payment was declined",
    error: "The payment failed",
    orderCallFail: "Something bad has happened. Order ID is incorrect or incomplete",
    dummyShippingMethodSet: "Shipping method was not set correctly. Please set it again",
    shippingMethodMissing: "Shipping method was not set correctly. Please set it again",
    countryError: "User is not available for this site",
    DEFAULT: "Something went wrong",
};
export const returnCorrectErrorMessage = (errorType) => ERROR_MESSAGE_MAP[errorType] || ERROR_MESSAGE_MAP.DEFAULT;
export const checkForTimeElapsed = (storageItem, maxDays) => {
    const today = dayjs().toDate();
    const lastPrompt = dayjs(localStorage.getItem(storageItem));
    const days = dayjs(today).diff(lastPrompt, "days");
    return isNaN(days) || days > maxDays;
};
export const shouldShowPopupModal = () => checkForTimeElapsed("popupModal", 7);
export const shouldShowCountryRedirectModal = (userCountry) => {
    if (process.env.NEXT_PUBLIC_COUNTRY === "US") {
        return ["NL", "BE", "DE"].includes(userCountry) && checkForTimeElapsed("countryRedirectModal", 7);
    }
    else {
        return userCountry !== process.env.NEXT_PUBLIC_COUNTRY && checkForTimeElapsed("countryRedirectModal", 7);
    }
};
export const shouldShowWishlistModal = () => checkForTimeElapsed("wishlistModal", 7);
export const setDisplayTime = (storageItem) => localStorage.setItem(storageItem, dayjs().toDate());
export const setPopupModalDisplayTime = () => setDisplayTime("popupModal");
export const setCountryRedirectModalDisplayTime = () => setDisplayTime("countryRedirectModal");
export const setWishlistModalTime = () => setDisplayTime("wishlistModal");
export const getIsCmsViewDeliveryOptionsEnabled = (deliveryOptions, deliveryName) => { var _a; return (_a = deliveryOptions === null || deliveryOptions === void 0 ? void 0 : deliveryOptions.find((item) => asText(item.develiry_name) === deliveryName)) === null || _a === void 0 ? void 0 : _a.is_delivery_enabled; };
export const getSelectedAttributes = (facet, pageUrl) => {
    const { selectiontype, urlkey } = facet.facetsettings;
    const attributes = facet.attributes
        .filter((attribute) => attribute.isselected)
        .map(({ title, url }) => ({
        title,
        url: selectiontype === "slider"
            ? pageUrl
                .split("&")
                .filter((param) => !param.includes(`tn_fk_${urlkey}`))
                .join("&")
            : url,
    }));
    return attributes.length ? { selectiontype, attributes } : null;
};
export const getBaseCategoryFromPrettyPath = (prettyPath) => {
    // We need to extract the base category path from the pretty paths that include one or more facets
    // e.g. `dames/jeans/merk_garcia/kleur_blauw` and `dames/jeans/maat_104/110` should return `dames/jeans`
    const hasSlashAfterFirstFacet = prettyPath.indexOf("_") > 0 && prettyPath.indexOf("_") < prettyPath.lastIndexOf("/");
    return hasSlashAfterFirstFacet
        ? prettyPath.split("_")[0].split("/").slice(0, -1)
        : prettyPath.split("/").filter((e) => !e.includes("_"));
};
export const getRobotsMetaTagBase = (facetsToIndex, pagesToDisallow) => (path) => {
    // Detect pages that are blacklisted in Robots config in Prismic
    const pathContainsFacetsToDisallow = pagesToDisallow.some((path_wildcard) => path.includes(path_wildcard));
    // Detect if path is regular or pretty
    const isPrettyPath = path.indexOf("_") > 0;
    // Detect if the (pretty) path contains facets that should be indexed (taken from a Prismic list)
    const pathContainsFacetsToIndex = facetsToIndex.map((f) => f + "_").some((facet) => path.includes(facet));
    // Detect if path contains multiple facets with special edge case handling for the Size facet case:
    // The "/" in the Size facet value is a special case that should be handled
    // The following regex tests for "_digit/digit" or "_digit/digit-digit/digit" at the end of a string
    // true  -> /dames/jeans/maat_28/32
    // true  -> /dames/jeans/maat_28/32-28/34
    // true  -> /dames/jeans/kleur_denim_blauw/maat_28/32
    // true  -> /dames/jeans/kleur_denim_blauw/maat_28/32-28/34
    // false -> /dames/jeans/maat_28/32/kleur_denim_blauw
    // false -> /dames/jeans/maat_28/32-28/34/kleur_denim_blauw
    const regex = /_(\d+\/\d+|\d+\/\d+-\d+\/\d+)$/;
    const lastFacetIsSize = regex.test(path);
    // Auxiliary bools
    const pathHasMoreThanOneUnderscore = path.indexOf("_") !== path.lastIndexOf("_");
    const pathHasSlashAfterFirstUnderscore = path.indexOf("_") < path.lastIndexOf("/");
    const pathLastUnderscoreIsAfterLastSlash = path.lastIndexOf("_") > path.lastIndexOf("/");
    const pathLastSlashIsAfterLastUnderscore = path.lastIndexOf("_") < path.lastIndexOf("/");
    // `/dames/jeans/skinny-fit/kleur_denim_blauw`             is false (one facet)
    // `/dames/jeans/skinny-fit/kleur_denim_blauw/merk_garcia` is true  (two facets)
    const pathContainsMultipleFacets = pathHasMoreThanOneUnderscore &&
        pathHasSlashAfterFirstUnderscore &&
        // The last underscore is after the last slash
        (pathLastUnderscoreIsAfterLastSlash ||
            // or there is a slash after the last underscore and the last facet is Size (special case)
            (pathLastSlashIsAfterLastUnderscore && lastFacetIsSize));
    // Detect if path contains any facet(s) with multiple values
    const pathContainsFacetWithMultipleValues = path.lastIndexOf("-") && path.indexOf("_") < path.lastIndexOf("-");
    // Evaluate whether page should be indexed or not
    const shouldIndex = 
    // 1. If page is blacklisted via Robots config in Prismic, disallow indexing
    !pathContainsFacetsToDisallow &&
        // 2. If normal category path (no filters), index the page
        (!isPrettyPath ||
            // 3. If it's a pretty path, only index the page if it obeys the rules stated in JOGGMNT-869
            // https://fyayc-am.atlassian.net/browse/JOGGMNT-869
            (pathContainsFacetsToIndex && !pathContainsMultipleFacets && !pathContainsFacetWithMultipleValues));
    return shouldIndex ? "index, follow" : "noindex, nofollow";
};
export const checkForIOS = () => {
    if (typeof window === "undefined" || window.navigator.standalone) {
        return false;
    }
    const ua = window.navigator.userAgent;
    const isIPad = !!ua.match(/iPad/i);
    const isIPhone = !!ua.match(/iPhone/i);
    return isIPad || isIPhone;
};
export const retry = async (fn, n = 3, sleepTime = 500) => {
    try {
        return fn();
    }
    catch (e) {
        if (n === 0) {
            console.log(`Retry fail`);
            throw e;
        }
        else {
            console.log(e);
            console.log(`Retry it, sleep ${sleepTime}ms, the rest time ${n}`);
            await sleep(sleepTime);
            return retry(fn, n - 1);
        }
    }
};
export const colorLanguageConverter = (color, productColorMap) => {
    const _color = (color || "").toLowerCase();
    return productColorMap[_color] || productColorMap.default;
};
export const textConvert = (text = "", textConversionMap) => {
    let convertedText = text;
    textConversionMap.forEach((item) => {
        const regExp = new RegExp(item.initValue, "gi");
        convertedText = convertedText === null || convertedText === void 0 ? void 0 : convertedText.replace(regExp, item.convertedValue);
    });
    return convertedText;
};
export const getDomain = () => {
    const site = (process.env.NEXT_PUBLIC_SITE_URL || "").replace(/\/*$/, "");
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "preview") {
        return process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL ? `https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}` : site;
    }
    return site;
};
const PRE_BUILD_PAGE_TYPE_DEFAULT = ["category", "service", "market"];
export const getPreBuildPageType = () => {
    const PRE_BUILD_PAGE_TYPE = process.env.PRE_BUILD_PAGE_TYPE || "";
    if (!PRE_BUILD_PAGE_TYPE)
        return PRE_BUILD_PAGE_TYPE_DEFAULT;
    try {
        return PRE_BUILD_PAGE_TYPE.split(",");
    }
    catch (e) {
        return PRE_BUILD_PAGE_TYPE_DEFAULT;
    }
};
export const fetchFeaturedProducts = async (_paths, locale) => {
    const redisCache = new RedisCache();
    const paths = [...new Set(_paths)];
    const ids = await Promise.all(paths.map((path) => redisCache.getProductId(path, locale).catch(() => null)));
    // const ids = redisItems.map((item) => (item?.type === "product" ? item.deps.ct.id : "")).filter(typedBoolean)
    const productsData = (await Promise.all(ids.filter(typedBoolean).map(({ id, path }) => {
        if (!id)
            return null;
        return getPdp(id)
            .then((product) => ({ product, path }))
            .catch(() => null);
    }))).filter(typedBoolean);
    const products = productsData
        .map(({ product, path }) => {
        var _a, _b, _c, _d, _e;
        const name = (_c = (((_a = product.name) === null || _a === void 0 ? void 0 : _a[process.env.NEXT_PUBLIC_LOCALE]) || ((_b = product.name) === null || _b === void 0 ? void 0 : _b["en"]))) !== null && _c !== void 0 ? _c : "";
        if (!name)
            return;
        const isOnStock = (_e = (_d = product.sizeGroup) === null || _d === void 0 ? void 0 : _d.sizes) === null || _e === void 0 ? void 0 : _e.some((item) => item.isOnStock);
        if (!isOnStock)
            return;
        const price = product.price;
        if (!price)
            return;
        product.attributes;
        return {
            price,
            firstPrice: product.firstPrice || null,
            discountedPrice: (product === null || product === void 0 ? void 0 : product.discountedPrice) || null,
            name,
            path,
            isOnStock,
            attributes: product.attributes,
            promotionalBanner: product.promotionalBanner || null,
            id: product.id,
        };
    })
        .filter(typedBoolean);
    return products;
};
export const handleCartError = async (fn, version, n = 5) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    try {
        return await fn(version);
    }
    catch (error) {
        if (error.statusCode === 400 && ((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.body) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.message) === "The cart is not in active state.") {
            localStorage.removeItem("createdCart");
            window.location.reload();
            throw error;
        }
        if (error.statusCode === 404 && ((_f = (_e = (_d = error === null || error === void 0 ? void 0 : error.body) === null || _d === void 0 ? void 0 : _d.errors) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.code) === "ResourceNotFound") {
            localStorage.removeItem("createdCart");
            window.location.reload();
            throw error;
        }
        if (error.statusCode === 409 && n > 0 && ((_h = (_g = error === null || error === void 0 ? void 0 : error.body) === null || _g === void 0 ? void 0 : _g.errors) === null || _h === void 0 ? void 0 : _h[0].currentVersion)) {
            const versionBody = (_j = error === null || error === void 0 ? void 0 : error.body) === null || _j === void 0 ? void 0 : _j.errors.find((item) => item.currentVersion);
            if (versionBody) {
                console.log(`version mismatched, the rest time ${n}`);
                return handleCartError(fn, error.body.errors[0].currentVersion, n - 1);
            }
            else {
                throw error;
            }
        }
        else {
            throw error;
        }
    }
};
export const NOT_FOUND = () => ({
    revalidate: getRevalidateTime(),
    notFound: true,
});
export const isAssetPath = (slug) => /(\.js|\.txt|\.css|\.jpg|\.png|\.svg|\.ico|\.xml|\.json)$/.test(slug);
export const normalizePdpImages = (images, sorting_by_name, sorting_by_quality) => {
    const group = images.reduce((pre, cur) => {
        if (!cur.label)
            return pre;
        const labels = cur.label.split("-");
        const name = labels.slice(0, -1).join("-");
        const quality = labels.at(-1);
        pre[name] = pre[name] || {};
        pre[name][quality] = { label: cur.label, url: cur.url };
        return pre;
    }, {});
    const sortingByName = [...sorting_by_name].reverse();
    const sortingByQuality = [...sorting_by_quality].reverse();
    const sortingByQualityOrder = ["2000", "1000", "500"];
    const groupAndSortByName = Object.entries(group)
        .map(([name, qualityGroup]) => {
        const index = sortingByName.findIndex((i) => i === name);
        return { qualityGroup, name, index };
    })
        .sort((a, b) => b.index - a.index);
    return groupAndSortByName
        .map(({ qualityGroup }) => {
        if (Object.keys(qualityGroup).length === 0)
            return null;
        const groupByQuality = Object.entries(qualityGroup).map(([quality, image]) => {
            const index = sortingByQuality.findIndex((i) => i === quality);
            const qualityOrder = sortingByQualityOrder.findIndex((i) => i === quality);
            return { image, index, qualityOrder };
        });
        const priority = groupByQuality.sort((a, b) => b.index - a.index).at(0);
        const lowest = groupByQuality.sort((a, b) => b.qualityOrder - a.qualityOrder).at(0);
        const highest = groupByQuality
            .filter((i) => i.qualityOrder > -1)
            .sort((a, b) => a.qualityOrder - b.qualityOrder)
            .at(0);
        return {
            ...priority.image,
            lowest: lowest.image.url,
            highest: highest.image.url,
        };
    })
        .filter(typedBoolean)
        .filter((i) => i.url);
};
export const transGtmId = ({ articleName, colorCode, }) => [articleName, colorCode].filter(typedBoolean).join("_").replace(/ /g, "_");
export const getGtmIdFromCT = (attributes) => {
    var _a, _b;
    const articleName = (((_a = attributes === null || attributes === void 0 ? void 0 : attributes.find((i) => i.name === "articlename")) === null || _a === void 0 ? void 0 : _a.value) || "");
    const colorCode = (((_b = attributes === null || attributes === void 0 ? void 0 : attributes.find((i) => i.name === "colorcode")) === null || _b === void 0 ? void 0 : _b.value) || "");
    return transGtmId({ articleName: articleName, colorCode: colorCode });
};
export const getArticleNameFromTW = (product) => {
    var _a, _b;
    const nameAndColorCode = (_b = (_a = product.attributes.find((i) => i.name === "nameAndColorCode")) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b[0];
    if (!nameAndColorCode)
        return null;
    try {
        return JSON.parse(nameAndColorCode);
    }
    catch (e) {
        console.error(e);
        return null;
    }
};
export const getGtmIdFromTW = (product) => {
    const data = getArticleNameFromTW(product);
    if (!data)
        return "";
    return transGtmId(data);
};
export const JC_FRIEND_SKU = "2400059866011";
export const getRevenue = (order) => {
    var _a, _b;
    const shipping = parseFloat(convertCentsToEuros((_a = order.shippingCosts) === null || _a === void 0 ? void 0 : _a.costs.centAmount));
    const tax = parseFloat(convertCentsToEuros((_b = order.totalTax) === null || _b === void 0 ? void 0 : _b.centAmount));
    const revenue = parseFloat(convertCentsToEuros(order.subtotal.centAmount - order.totalDiscount.centAmount));
    return {
        shipping,
        tax,
        revenue,
    };
};
export const replaceTextInRichText = (rawText, searchValue, replaceValue) => {
    try {
        if (rawText) {
            return JSON.parse(JSON.stringify(rawText).replace(searchValue, replaceValue));
        }
    }
    catch (error) {
        console.log(error, "parse rich text error");
    }
    return rawText;
};
export const getRevalidateTime = () => {
    const NEXT_REVALIDATE_TIME = +(process.env.NEXT_REVALIDATE_TIME || "");
    return NEXT_REVALIDATE_TIME || 60 * 20;
};
export const replacePlaceholderInUrl = (url) => {
    return url.replace(/https:\/\/(\{site}|\/?\$)/, "");
};
export const lowerCaseEq = (a, b) => a.toLowerCase() === b.toLowerCase();
export const fetchResponse = async (response) => {
    if (response.ok) {
        return (await response.json());
    }
    const errorBody = await response.text();
    throw new Error(errorBody || response.statusText);
};
export const lowerCaseLocale = () => { var _a; return ((_a = process.env.NEXT_PUBLIC_LOCALE) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || ""; };
export function getDomains() {
    try {
        return JSON.parse(process.env.DOMAINS || "[]");
    }
    catch {
        return [];
    }
}
export function getPaths(attributes) {
    var _a;
    if (!attributes)
        throw new Error("attributes empty");
    const domains = getDomains();
    const paths = ((_a = attributes.find((i) => i.name === "path")) === null || _a === void 0 ? void 0 : _a.value) || {};
    paths["en-US"] = paths["en-US"] || paths["en"];
    return domains
        .map(({ locale, domain }) => {
        const path = paths[locale];
        if (!path)
            return;
        return { locale, url: [domain.replace(/\/$/, ""), path.replace(/^\//, "")].join("/") };
    })
        .filter(typedBoolean);
}
export const dateTimeFormat = (date, options) => {
    return new Intl.DateTimeFormat(lowerCaseLocale(), {
        year: "numeric",
        month: "short",
        day: "numeric",
        ...options,
    }).format(new Date(date));
};
