import { lookupAddress } from "@jog/shared";
import { useCallback } from "react";
import { usePending } from "../components";
export const useCheckDutchAddress = () => {
    const { setPending } = usePending();
    const getAddress = useCallback(async (values, errors, setFieldValue) => {
        var _a;
        if (((_a = values.country) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== "NL")
            return;
        if (errors.postalCode)
            return;
        if (errors.houseNumber)
            return;
        const { postalCode, houseNumber } = values;
        setPending(true);
        try {
            const res = await lookupAddress(postalCode || "", houseNumber || "");
            if (!res)
                return;
            setFieldValue("city", res.city || "");
            setFieldValue("streetName", res.streetname || "");
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setPending(false);
        }
    }, [setPending]);
    return { getAddress };
};
