import { AWSClient } from "./index";
const CHANNEL = process.env.NEXT_PUBLIC_WEBSHOP;
const LANGUAGE = process.env.NEXT_PUBLIC_LOCALE;
export const commerceClient = () => AWSClient(process.env.NEXT_PUBLIC_AWS_COMMERCE_ENDPOINT || "");
export const getWishlist = (wishlistId) => commerceClient()({
    method: "GET",
    uri: `/wishlist/${wishlistId}?channel=${CHANNEL}&language=${LANGUAGE}`,
});
export const addToWishlist = (id, productId) => commerceClient()({
    method: "POST",
    uri: `/wishlist`,
    body: { channel: CHANNEL, language: LANGUAGE, id, productId },
});
export const getSimpleWishlist = (wishlistId) => commerceClient()({
    method: "GET",
    uri: `/wishlist/simple/${wishlistId}?channel=${CHANNEL}&language=${LANGUAGE}`,
});
export const mergeWishlist = (id) => commerceClient()({
    method: "POST",
    uri: "/wishlist/merge",
    body: { channel: CHANNEL, language: LANGUAGE, id },
});
export const myWishlist = () => commerceClient()({
    method: "GET",
    uri: `/wishlist?channel=${CHANNEL}&language=${LANGUAGE}`,
});
export const removeFromWishlist = (id, lineItemId) => commerceClient()({
    method: "PUT",
    uri: `/wishlist/${id}`,
    body: { id, lineItemId },
});
export const getCart = (id) => commerceClient()({
    method: "GET",
    uri: `/cart/${id}`,
});
export const getOrder = (order) => commerceClient()({
    method: "GET",
    uri: `/order/${order}`,
}).then((res) => res.body);
export const lookupAddress = (postcode, houseNumber) => commerceClient()({
    method: "GET",
    uri: `/address/lookup/${postcode}/${houseNumber}`,
}).then((res) => res.body);
export const getPdp = (id) => commerceClient()({
    method: "GET",
    uri: `/product/${process.env.NEXT_PUBLIC_WEBSHOP}/${id}`,
}).then((res) => res.body);
export const getVariants = async (skus) => {
    return commerceClient()({
        method: "GET",
        uri: `/products/skus?sku=${skus.join(",")}`,
    }).then((res) => res.body);
};
