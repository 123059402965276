import { GraphQLClient } from "graphql-request";
import { getSdk } from "./generated";
let client = null;
let token = null;
async function getToken() {
    const basicAuth = Buffer.from(`${process.env.CTP_CLIENT_ID}:${process.env.CTP_CLIENT_SECRET}`).toString("base64");
    const scope = process.env.CTP_SCOPES;
    const res = await fetch(`${process.env.CTP_AUTH_URL}/oauth/token`, {
        method: "POST",
        headers: {
            Authorization: `Basic ${basicAuth}`,
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `grant_type=client_credentials${scope ? `&scope=${scope}` : ""}`,
    });
    const auth = (await res.json());
    const expires_at = new Date().getTime() + (auth.expires_in - 300) * 1000;
    return { auth, expires_at };
}
export const createClient = () => {
    if (client)
        return client;
    const clientUrl = process.env.CTP_API_URL + "/" + process.env.CTP_PROJECT_KEY + "/graphql";
    return getSdk(new GraphQLClient(clientUrl, {}), async (action) => {
        if (!token || new Date().getTime() > token.expires_at) {
            token = await getToken();
        }
        return action({
            "Content-Type": "application/json",
            Authorization: `${token.auth.token_type} ${token.auth.access_token}`,
        });
    });
};
