import { getLocalAttributesRaw, getStringAttributesRaw, mapAttributes } from "@jog/ct";
import { getGtmIdFromCT, getPaths, normalizePdpImages, typedBoolean } from "../helper";
export const buildProductDetailInfo = ({ product, prismic, pdpSizesJson, brandsJson, }, locale) => {
    var _a, _b, _c;
    if (!product)
        throw new Error("Not found product");
    const data = product.masterData.current;
    if (!data)
        throw new Error("Not found product.masterData.current");
    const slug = data.slug || "";
    const category = (_a = data.categories.sort((a, b) => +a.orderHint - +b.orderHint)) === null || _a === void 0 ? void 0 : _a.at(0);
    const productCategoryPath = getLocalAttributesRaw(((_b = category === null || category === void 0 ? void 0 : category.custom) === null || _b === void 0 ? void 0 : _b.customFieldsRaw) || [], "path", locale);
    const attributes = [
        ...(data.masterVariant.attributesRaw || []),
        {
            name: "articlenumber",
            value: slug.split("-").slice(1).join("-").toUpperCase(),
        },
    ];
    const desktopImages = normalizePdpImages(data.masterVariant.images, prismic.desktopSortingByName || [], prismic.sortingByQuality || []);
    const mobileImages = normalizePdpImages(data.masterVariant.images, prismic.mobileSortingByName || [], prismic.sortingByQuality || []);
    const isFasletShow = prismic.faslet && prismic.categoryKeys.some((item) => item === (category === null || category === void 0 ? void 0 : category.key));
    const sizeImage = (pdpSizesJson === null || pdpSizesJson === void 0 ? void 0 : pdpSizesJson.find((item) => item.key === (category === null || category === void 0 ? void 0 : category.key))) || null;
    const ctBrand = ((_c = attributes.find((i) => i.name === "brand")) === null || _c === void 0 ? void 0 : _c.value) || "";
    const brand = brandsJson
        ? (brandsJson === null || brandsJson === void 0 ? void 0 : brandsJson.find((i) => i.brandId === ctBrand.replace(" ", "_").toLowerCase())) || null
        : null;
    const gtmId = getGtmIdFromCT(attributes) || "";
    const name = (data === null || data === void 0 ? void 0 : data.name) || "";
    const description = (data === null || data === void 0 ? void 0 : data.description) || "";
    const categories = data.categories.map((val) => val.id) || [];
    const color = getLocalAttributesRaw(attributes, "webcolor", locale);
    const material = getLocalAttributesRaw(attributes, "material", locale);
    const size = getStringAttributesRaw(attributes, "size");
    const parent = getStringAttributesRaw(attributes, "parent");
    const mappedAttributes = mapAttributes(attributes, prismic.attributes, locale).filter(typedBoolean);
    const sustainable = prismic.sustainable.filter((slice) => categories.includes(slice.id)).map((i) => i.items) || [];
    return {
        ctpId: (product === null || product === void 0 ? void 0 : product.id) || "",
        desktopImages,
        mobileImages,
        isFasletShow,
        sizeImage,
        size,
        brand,
        gtmId,
        name,
        description,
        slug,
        color,
        mappedAttributes,
        material,
        sustainable,
        paths: getPaths(attributes),
        published: product.masterData.published,
        parent,
        productCategoryPath,
    };
};
