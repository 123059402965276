import { createApiBuilderFromCtpClient } from "@commercetools/platform-sdk";
import { ClientBuilder } from "@commercetools/sdk-client-v2";
const projectKey = `${process.env.CTP_PROJECT_KEY}`;
const scopes = [`${process.env.CTP_SCOPES}`];
// Configure authMiddlewareOptions
const authMiddlewareOptions = {
    host: `${process.env.CTP_AUTH_URL}`,
    projectKey,
    credentials: {
        clientId: `${process.env.CTP_CLIENT_ID}`,
        clientSecret: `${process.env.CTP_CLIENT_SECRET}`,
    },
    scopes,
};
// Configure httpMiddlewareOptions
const httpMiddlewareOptions = {
    host: `${process.env.CTP_API_URL}`,
    retryConfig: { maxRetries: 3 },
};
// Export the ClientBuilder
export const _ctpClient = new ClientBuilder()
    .withProjectKey(projectKey) // .withProjectKey() is not required if the projectKey is included in authMiddlewareOptions
    .withClientCredentialsFlow(authMiddlewareOptions)
    .withHttpMiddleware(httpMiddlewareOptions)
    // .withLoggerMiddleware() // Include middleware for logging
    .build();
export const apiRoot = createApiBuilderFromCtpClient(_ctpClient).withProjectKey({ projectKey });
