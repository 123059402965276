import { createClient } from "@jog/ct";
import { fetchS3CategoryPage } from "../aws/s3";
import { getPaths, retry } from "../helper";
export const buildCategoryPage = async (client, redisItem, pageContext) => {
    var _a, _b, _c, _d;
    const { deps, extra, locale, ...others } = redisItem;
    const listerData = await retry(() => createClient()
        .category({ id: deps.ct.id, locale })
        .then((res) => res.category));
    if (!listerData)
        throw new Error("Get ct category fail");
    const paths = getPaths((_a = listerData.custom) === null || _a === void 0 ? void 0 : _a.customFieldsRaw);
    if (!paths)
        throw new Error("Ct category does not have path");
    const title = listerData.description || "";
    const type = ((_b = deps.prismic) === null || _b === void 0 ? void 0 : _b.type) || "lister";
    const prismicId = (_c = redisItem.deps.prismic) === null || _c === void 0 ? void 0 : _c.id;
    switch (type) {
        case "shop_the_look": {
            if (!prismicId)
                throw new Error("Ct category does not have path");
            const prismic = await retry(() => pageContext.preview && pageContext.previewData
                ? client.getByID(prismicId)
                : fetchS3CategoryPage("shop_the_look", prismicId));
            return {
                locale,
                ...others,
                categoryInfo: { ...extra, paths, title },
                prismic,
                type: "shopTheLook",
            };
        }
        case "category": {
            if (!prismicId)
                throw new Error("Ct category does not have path");
            const prismic = await retry(() => pageContext.preview && pageContext.previewData
                ? client.getByID(prismicId, {
                    fetchLinks: ["category_nav.body"],
                })
                : fetchS3CategoryPage("category", prismicId));
            return {
                locale,
                ...others,
                categoryInfo: { ...extra, paths, title },
                prismic,
                type: "category",
            };
        }
        case "lister": {
            const prismic = prismicId
                ? await retry(() => pageContext.preview && pageContext.previewData
                    ? client.getByID(prismicId)
                    : fetchS3CategoryPage("lister", prismicId))
                : null;
            return {
                locale,
                ...others,
                categoryInfo: {
                    ...extra,
                    pretty: `/${pageContext.params.slug.join("/")}`,
                    paths,
                    title,
                },
                type: "lister",
                prismic,
            };
        }
        default: {
            throw new Error(`deps.prismic?.type error: ${(_d = deps.prismic) === null || _d === void 0 ? void 0 : _d.type}`);
        }
    }
};
