import { gql } from "graphql-request";
export const allCategorysQuery = gql `
    query allCategorysQuery($lang: String!, $after: String) {
        content: allCategorys(lang: $lang, after: $after) {
            edges {
                node {
                    _meta {
                        lang
                        type
                        id
                        uid
                    }
                }
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;
export const allListersQuery = gql `
    query allListersQuery($lang: String!, $after: String) {
        content: allListers(lang: $lang, after: $after) {
            edges {
                node {
                    _meta {
                        lang
                        type
                        id
                        uid
                    }
                }
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;
export const allShopTheLooksQuery = gql `
    query allShopTheLooksQuery($lang: String!, $after: String) {
        content: allShop_the_looks(lang: $lang, after: $after) {
            edges {
                node {
                    _meta {
                        lang
                        type
                        id
                        uid
                    }
                }
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;
