import { useEffect, useState } from "react";
const calculateTimeLeft = (end) => {
    const difference = end.getTime() - new Date().getTime();
    if (difference <= 0)
        return null;
    return {
        dagen: Math.floor(difference / (1000 * 60 * 60 * 24)),
        uur: Math.floor((difference / (1000 * 60 * 60)) % 24),
        min: Math.floor((difference / 1000 / 60) % 60),
        sec: Math.floor((difference / 1000) % 60),
    };
};
export const Countdown = (fields) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(fields.end));
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(fields.end));
        }, 1000);
        return () => clearTimeout(timer);
    });
    if (!timeLeft)
        return <></>;
    return (<span>
            {Object.entries(timeLeft).map(([interval, value]) => (<span key={interval}>{`${value} ${interval}`}</span>))}
        </span>);
};
