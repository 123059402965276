import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
export const ModalBase = ({ modalBoxClass, modalTitleClass, isJC = false, Close }) => {
    const ModalFC = ({ children, headerTitle, hideModal, width, height, backdropDismiss = true, background = {}, isSecondlyClose = false, modalClass, SecondlyClose, contentClass, headerClass, }) => {
        const [isVisible, setIsVisible] = useState(true);
        useEffect(() => {
            if (isVisible) {
                document.body.style.overflow = "hidden";
                return () => {
                    document.body.style.overflow = "unset";
                };
            }
        }, [isVisible]);
        const handleClickCloseModal = useCallback(() => {
            // TODO: it might be possible to get rid of the hideModal props altogether,
            //       because I think all they do is manage state somewhere that controls the visibility.
            //       And this behavior can (should) be controlled entirely by the modal component itself, unless there are other side effects in addition to closing it
            if (hideModal) {
                hideModal();
            }
            else {
                setIsVisible(false);
            }
        }, [hideModal]);
        return isVisible ? (<div className="fixed inset-0 z-[9] bg-black/70" onClick={() => backdropDismiss && handleClickCloseModal()}>
                <div className="flex h-full w-full" data-modal="modalbox">
                    <div className={classNames("z-[9] m-auto flex h-[80%] max-h-[600px] w-[80%] min-w-[300px] max-w-[800px] flex-col bg-white bg-cover px-5 pt-2.5 pb-2.5", modalBoxClass, modalClass)} style={{
                height: height,
                width: width,
                background: background.desktop
                    ? `url(${background === null || background === void 0 ? void 0 : background.desktop}) no-repeat center center`
                    : "white",
            }} onClick={(e) => e.stopPropagation()}>
                        <div className={classNames("flex justify-between", {
                "mb-5 border-b border-gray-silver pb-5": isJC && !!headerTitle,
                "mb-2.5 border-b border-transparent pb-0": isJC && !headerTitle,
            }, headerClass)}>
                            <div className={classNames("mr-2.5 text-center uppercase", modalTitleClass)}>
                                {headerTitle}
                            </div>
                            {isSecondlyClose && SecondlyClose ? (<SecondlyClose onClick={handleClickCloseModal}/>) : (<Close onClick={handleClickCloseModal}/>)}
                        </div>
                        <div className={classNames("overflow-y-auto overflow-x-hidden text-center", contentClass)}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>) : null;
    };
    return ModalFC;
};
