import { typedBoolean } from "@jog/shared";
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NextImage } from "../NextImage/NextImage";
const PLACEHOLDER_SRC = `data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D`;
const LazyLoadImage = ({ image, inView }) => (<NextImage alt={image.label} height="500" width="330" blur={false} loading="lazy" className="img-fluid w-full" src={inView ? image.lowest : PLACEHOLDER_SRC}/>);
const ListerTileImageCarousel = ({ images, className }) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
    const [slidesInView, setSlidesInView] = useState([0]);
    const updateSlidesInView = useCallback((emblaApi) => {
        setSlidesInView((slidesInView) => {
            if (slidesInView.length === emblaApi.slideNodes().length) {
                emblaApi.off("slidesInView", updateSlidesInView);
            }
            const inView = emblaApi.slidesInView().filter((index) => !slidesInView.includes(index));
            return slidesInView.concat(inView);
        });
    }, []);
    useEffect(() => {
        if (!emblaApi)
            return;
        updateSlidesInView(emblaApi);
        emblaApi.on("slidesInView", updateSlidesInView);
        emblaApi.on("reInit", updateSlidesInView);
    }, [emblaApi, updateSlidesInView]);
    return (<div ref={emblaRef} className={classNames("w-full overflow-hidden", className)}>
            <div className="flex h-full w-full">
                {images.map((image, index) => (<div className="flex w-full flex-shrink-0 flex-grow basis-full" key={image.url}>
                        <LazyLoadImage image={image} inView={slidesInView.includes(index)}/>
                    </div>))}
            </div>
        </div>);
};
export const ListerTileImage = ({ gallery, name }) => {
    // When there is no flatshot image available then the hover/swipe functionality is not needed
    // When there is only a flatshot image available then the hover/swipe functionality is not needed
    const enableHover = useMemo(() => [...new Set(gallery.desktop.map((i) => i.label).filter(typedBoolean))].length === 2, [gallery.desktop]);
    return (<div className="aspect-w-33 aspect-h-50">
            <div className={classNames("group hidden cursor-pointer lg:block")}>
                <NextImage alt={name} height={500} width={330} className={classNames("block h-full w-full object-cover", {
            "group-hover:hidden": enableHover,
        })} src={gallery.desktop.at(0).url}/>
                {enableHover ? (<NextImage alt={name} height={500} width={330} loading="lazy" blur={false} className="hidden h-full w-full object-cover group-hover:block" src={gallery.desktop.at(1).url}/>) : (<></>)}
            </div>
            {!!gallery.mobile.length && <ListerTileImageCarousel className="block lg:hidden" images={gallery.mobile}/>}
        </div>);
};
