import { Redis } from "@upstash/redis";
import chunk from "lodash/chunk";
import { sleep } from "../helper";
const ALL_PATHS = `ALL_PATHS`;
const GROUP_SPLIT = " ";
const PAGE_SIZE = 100;
const SLEEP_TIME = 1000;
const EX = 259200; //3 * 24 * 60 * 60
const TYPES = ["product", "category", "service", "market"];
export class RedisCache {
    constructor(logger = console.log) {
        this.logger = logger;
        this.write = async (locale, pages) => {
            await this.setAll(pages);
            await Promise.all(TYPES.map((type) => this.setGroup(locale, pages, type)));
        };
        this.failArr = [];
        this.setGroup = async (locale, pages, type) => {
            const paths = pages.filter((i) => i.type === type).map((i) => i.path);
            this.logger(`set ${locale} ${type} pages: ${paths.length}`);
            await this.redis.set(this.key(locale, `${ALL_PATHS}:${type}`), paths.join(GROUP_SPLIT));
        };
        this.getGroup = async (locale, type) => {
            const pages = await this.redis
                .get(this.key(locale, `${ALL_PATHS}:${type}`))
                .then((res) => (res === null || res === void 0 ? void 0 : res.split(GROUP_SPLIT)) || []);
            this.logger(`get ${locale} ${type} pages: ${pages.length}`);
            return pages;
        };
        this.getGroups = async (locale, types) => {
            const pages = await Promise.all(types.map((type) => this.getGroup(locale, type)));
            return pages.flat();
        };
        this.setAll = async (pages) => {
            this.failArr = [];
            await this.setChunk(pages);
            await this.reSet();
            this.logger(`Write ${pages.length} pages to redis success`);
        };
        this.reSet = async (n = 0) => {
            if (n > 3) {
                this.logger(`[ReSet] reset time over 3`);
                return;
            }
            if (this.failArr.length) {
                const failArr = [...this.failArr];
                this.failArr = [];
                await this.setChunk(failArr);
                await this.reSet(n + 1);
            }
        };
        this.set = async (page) => {
            try {
                await this.redis.set(this.key(page.locale, page.path.replace(/^\//, "")), { ...page, t: new Date().getTime() }, { ex: EX });
                return page;
            }
            catch (error) {
                this.logger(error);
                this.failArr.push(page);
                return null;
            }
        };
        this.fetch = async (urlPath, locale) => {
            return this.redis.get(this.key(locale, urlPath));
        };
        this.getProductId = async (path, locale) => {
            const redisItem = await this.fetch(path, locale);
            if ((redisItem === null || redisItem === void 0 ? void 0 : redisItem.type) !== "product")
                return;
            return {
                id: redisItem.deps.ct.id,
                path,
                locale,
            };
        };
        try {
            this.redis = Redis.fromEnv();
        }
        catch (error) {
            this.logger(`Failed to initialize Redis: ${error}`);
            throw error;
        }
    }
    async setChunk(pages) {
        const total = pages.length;
        const pagesChunk = chunk(pages, PAGE_SIZE);
        this.logger(`Total items: ${total}`);
        this.logger(`Total chunks: ${pagesChunk.length}`);
        for (let group = 0; group < pagesChunk.length; group++) {
            await Promise.all(pagesChunk[group].map((page) => this.set(page)));
            this.logger(`(${group + 1}/${pagesChunk.length}) Group cache set`);
            await sleep(SLEEP_TIME);
        }
    }
    key(locale, path) {
        return `slug:${locale.toLowerCase()}:${path}`;
    }
}
