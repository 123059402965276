var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"141ca8f72d24ec97bb07908ab5d730b260998078"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs"

if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "true") {
    const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

    Sentry.init({
        dsn:
            SENTRY_DSN ||
            "https://6849d3480ae44f519255024d14cec621@o4504685361627136.ingest.sentry.io/4504700908404736",
        // Adjust this value in production, or use tracesSampler for greater control
		tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE, // For Performance Metrics we want around 0.0133 due to high amount of traffic
		sampleRate: process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE, // For Error Events we want a different number. a 0.4 is fine.
    })
}
