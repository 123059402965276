import { NextImage, RichTextBase, cn } from "@jog/react-components";
import { replacePlaceholderInUrl } from "@jog/shared";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import * as React from "react";
export const GRichText = RichTextBase({ paragraphDefaultCss: "text-sm" });
const sizes = {
    xs: ["text-base", "text-sm", "text-xs"],
    sm: ["text-lg", "text-base", "text-sm"],
};
export const RichText = ({ field, replace, size, }) => {
    const font = sizes[size || "xs"] || sizes.xs;
    return (<PrismicRichText field={field} components={{
            heading1: ({ children }) => (<h1 className={cn("mb-3 text-[26px] font-medium leading-tight text-black")}>{children}</h1>),
            heading2: ({ children }) => (<h2 className={cn("mb-2 text-[22px] font-medium leading-tight text-black")}>{children}</h2>),
            heading3: ({ children }) => (<h3 className={cn("mb-1 text-lg font-medium leading-tight text-black")}>{children}</h3>),
            heading4: ({ children }) => <h4 className="mb-0.5 text-base leading-tight text-black">{children}</h4>,
            heading5: ({ children }) => <h5 className="mb-0.5 text-base leading-tight text-black">{children}</h5>,
            heading6: ({ children }) => <h6 className="mb-0.5 text-base leading-tight text-black">{children}</h6>,
            span: ({ text, key }) => {
                const result = [];
                const _text = replace ? text.replace(/{([^}]+)}/g, (ori, k) => replace[k] || ori) : text;
                let i = 0;
                for (const line of _text.split("\n")) {
                    if (i > 0) {
                        result.push(<br key={`${i}__break`}/>);
                    }
                    result.push(<React.Fragment key={`${i}__line`}>{line}</React.Fragment>);
                    i++;
                }
                return <React.Fragment key={key}>{result}</React.Fragment>;
            },
            paragraph: ({ children, node, text }) => {
                if (!node.spans.length && !text) {
                    return (<p className={cn("font-normal leading-tight text-black", font[2])}>
                                <br />
                            </p>);
                }
                return <p className={cn("font-normal leading-tight text-black", font[2])}>{children}</p>;
            },
            list: ({ children }) => (<ul className="list-disc pl-10 font-normal leading-tight text-black">{children}</ul>),
            oList: ({ children }) => (<ol className="list-decimal pl-10 font-normal leading-tight text-black">{children}</ol>),
            hyperlink: ({ node, children }) => {
                if (node.data.url) {
                    node.data.url = replacePlaceholderInUrl(node.data.url).replace(process.env.NEXT_PUBLIC_SITE_URL || "", "");
                    node.data.url = node.data.url.replace(/https:\/\/tel*:/, "tel:");
                    node.data.url = node.data.url.replace(/https:\/\/mailto:/, "mailto:");
                }
                const target = node.data.link_type === "Web" && node.data.target ? node.data.target : "_self";
                return (<PrismicLink field={node.data} target={target} className="text-black underline">
                            {children}
                        </PrismicLink>);
            },
            image: ({ node }) => (<p className="overflow-x-auto">
                        <NextImage className="max-w-none" width={node.dimensions.width} height={node.dimensions.height} src={node.url} alt={node.alt || ""}/>
                    </p>),
        }}/>);
};
