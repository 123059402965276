import gql from 'graphql-tag';
export var ActionType;
(function (ActionType) {
    ActionType["Create"] = "Create";
    ActionType["Update"] = "Update";
})(ActionType || (ActionType = {}));
export var AnonymousCartSignInMode;
(function (AnonymousCartSignInMode) {
    /**
     * `LineItem`s of the anonymous cart will be copied to the customer’s active cart that has been modified most recently.
     *
     * The `CartState` of the anonymous cart gets changed to `Merged` while the `CartState` of the customer’s cart remains `Active`.
     *
     * `CustomLineItems` and `CustomFields` of the anonymous cart will not be copied to the customers cart.
     *
     * If a `LineItem` in the anonymous cart matches an existing line item in the customer’s cart (same product ID and variant ID), the maximum quantity of both LineItems is used as the new quantity. In that case `CustomFields` on the `LineItem` of the anonymous cart will not be in the resulting `LineItem`.
     */
    AnonymousCartSignInMode["MergeWithExistingCustomerCart"] = "MergeWithExistingCustomerCart";
    /** The anonymous cart is used as new active customer cart. No `LineItem`s get merged. */
    AnonymousCartSignInMode["UseAsNewActiveCustomerCart"] = "UseAsNewActiveCustomerCart";
})(AnonymousCartSignInMode || (AnonymousCartSignInMode = {}));
export var ApprovalRuleStatus;
(function (ApprovalRuleStatus) {
    ApprovalRuleStatus["Active"] = "Active";
    ApprovalRuleStatus["Inactive"] = "Inactive";
})(ApprovalRuleStatus || (ApprovalRuleStatus = {}));
export var AssociateInheritanceMode;
(function (AssociateInheritanceMode) {
    AssociateInheritanceMode["Disabled"] = "Disabled";
    AssociateInheritanceMode["Enabled"] = "Enabled";
})(AssociateInheritanceMode || (AssociateInheritanceMode = {}));
export var AttributeConstraint;
(function (AttributeConstraint) {
    /** A set of attributes, that have this constraint, should have different combinations in each variant */
    AttributeConstraint["CombinationUnique"] = "CombinationUnique";
    /** No constraints are applied to the attribute */
    AttributeConstraint["None"] = "None";
    /** Attribute value should be the same in all variants */
    AttributeConstraint["SameForAll"] = "SameForAll";
    /** Attribute value should be different in each variant */
    AttributeConstraint["Unique"] = "Unique";
})(AttributeConstraint || (AttributeConstraint = {}));
export var AttributionSource;
(function (AttributionSource) {
    AttributionSource["Export"] = "Export";
    AttributionSource["Import"] = "Import";
})(AttributionSource || (AttributionSource = {}));
/** AuthenticationMode values. */
export var AuthenticationMode;
(function (AuthenticationMode) {
    AuthenticationMode["ExternalAuth"] = "ExternalAuth";
    AuthenticationMode["Password"] = "Password";
})(AuthenticationMode || (AuthenticationMode = {}));
export var AwsAuthenticationMode;
(function (AwsAuthenticationMode) {
    AwsAuthenticationMode["Credentials"] = "Credentials";
    AwsAuthenticationMode["Iam"] = "IAM";
})(AwsAuthenticationMode || (AwsAuthenticationMode = {}));
export var BusinessUnitApprovalRuleMode;
(function (BusinessUnitApprovalRuleMode) {
    BusinessUnitApprovalRuleMode["Explicit"] = "Explicit";
    BusinessUnitApprovalRuleMode["ExplicitAndFromParent"] = "ExplicitAndFromParent";
})(BusinessUnitApprovalRuleMode || (BusinessUnitApprovalRuleMode = {}));
export var BusinessUnitAssociateMode;
(function (BusinessUnitAssociateMode) {
    BusinessUnitAssociateMode["Explicit"] = "Explicit";
    BusinessUnitAssociateMode["ExplicitAndFromParent"] = "ExplicitAndFromParent";
})(BusinessUnitAssociateMode || (BusinessUnitAssociateMode = {}));
export var BusinessUnitConfigurationStatus;
(function (BusinessUnitConfigurationStatus) {
    BusinessUnitConfigurationStatus["Active"] = "Active";
    BusinessUnitConfigurationStatus["Inactive"] = "Inactive";
})(BusinessUnitConfigurationStatus || (BusinessUnitConfigurationStatus = {}));
/** BETA: This feature can be subject to change and should be used carefully in production. https://docs.commercetools.com/api/contract#public-beta */
export var BusinessUnitSearchStatus;
(function (BusinessUnitSearchStatus) {
    BusinessUnitSearchStatus["Activated"] = "Activated";
    BusinessUnitSearchStatus["Deactivated"] = "Deactivated";
})(BusinessUnitSearchStatus || (BusinessUnitSearchStatus = {}));
export var BusinessUnitStatus;
(function (BusinessUnitStatus) {
    BusinessUnitStatus["Active"] = "Active";
    BusinessUnitStatus["Inactive"] = "Inactive";
})(BusinessUnitStatus || (BusinessUnitStatus = {}));
export var BusinessUnitType;
(function (BusinessUnitType) {
    BusinessUnitType["Company"] = "Company";
    BusinessUnitType["Division"] = "Division";
})(BusinessUnitType || (BusinessUnitType = {}));
export var CartOrigin;
(function (CartOrigin) {
    /** The cart was created by the customer. This is the default value */
    CartOrigin["Customer"] = "Customer";
    /** The cart was created by the merchant on behalf of the customer */
    CartOrigin["Merchant"] = "Merchant";
    /** The cart was created by our platform and belongs to a Quote. */
    CartOrigin["Quote"] = "Quote";
})(CartOrigin || (CartOrigin = {}));
export var CartState;
(function (CartState) {
    /** The cart can be updated and ordered. It is the default state. */
    CartState["Active"] = "Active";
    /** The cart is frozen. The cart is ready for checkout and a few update actions are not allowed. */
    CartState["Frozen"] = "Frozen";
    /** Anonymous cart whose content was merged into a customers cart on signin. No further operations on the cart are allowed. */
    CartState["Merged"] = "Merged";
    /** The cart was ordered. No further operations on the cart are allowed. */
    CartState["Ordered"] = "Ordered";
})(CartState || (CartState = {}));
export var ChannelRole;
(function (ChannelRole) {
    /** Role tells that this channel can be used to track inventory entries.Channels with this role can be treated as warehouses */
    ChannelRole["InventorySupply"] = "InventorySupply";
    /** Role tells that this channel can be used to track order export activities. */
    ChannelRole["OrderExport"] = "OrderExport";
    /** Role tells that this channel can be used to track order import activities. */
    ChannelRole["OrderImport"] = "OrderImport";
    /** This role can be combined with some other roles (e.g. with `InventorySupply`) to represent the fact that this particular channel is the primary/master channel among the channels of the same type. */
    ChannelRole["Primary"] = "Primary";
    /** Role tells that this channel can be used to expose products to a specific distribution channel. It can be used by the cart to select a product price. */
    ChannelRole["ProductDistribution"] = "ProductDistribution";
})(ChannelRole || (ChannelRole = {}));
export var CustomLineItemPriceMode;
(function (CustomLineItemPriceMode) {
    /** Cart discounts are deactivated for the custom line items with this price mode. */
    CustomLineItemPriceMode["External"] = "External";
    /** This is the default mode. */
    CustomLineItemPriceMode["Standard"] = "Standard";
})(CustomLineItemPriceMode || (CustomLineItemPriceMode = {}));
export var CustomerSearchStatus;
(function (CustomerSearchStatus) {
    CustomerSearchStatus["Activated"] = "Activated";
    CustomerSearchStatus["Deactivated"] = "Deactivated";
})(CustomerSearchStatus || (CustomerSearchStatus = {}));
export var DiscountApplicationMode;
(function (DiscountApplicationMode) {
    DiscountApplicationMode["EvenDistribution"] = "EvenDistribution";
    DiscountApplicationMode["IndividualApplication"] = "IndividualApplication";
    DiscountApplicationMode["ProportionateDistribution"] = "ProportionateDistribution";
})(DiscountApplicationMode || (DiscountApplicationMode = {}));
export var DiscountCodeState;
(function (DiscountCodeState) {
    /** The discount code is active and none of the discounts were applied because the discount application was stopped by one discount that has the StackingMode of StopAfterThisDiscount defined */
    DiscountCodeState["ApplicationStoppedByPreviousDiscount"] = "ApplicationStoppedByPreviousDiscount";
    /** The discount code is active and it contains at least one active and valid CartDiscount. But its cart predicate does not match the cart or none of the contained active discount’s cart predicates match the cart */
    DiscountCodeState["DoesNotMatchCart"] = "DoesNotMatchCart";
    /** The discount code is active and it contains at least one active and valid CartDiscount. The discount code cartPredicate matches the cart and at least one of the contained active discount’s cart predicates matches the cart. */
    DiscountCodeState["MatchesCart"] = "MatchesCart";
    /** maxApplications or maxApplicationsPerCustomer for discountCode has been reached. */
    DiscountCodeState["MaxApplicationReached"] = "MaxApplicationReached";
    /** The discount code is not active or it does not contain any active cart discounts. */
    DiscountCodeState["NotActive"] = "NotActive";
    /** The discount code is not valid or it does not contain any valid cart discounts. Validity is determined based on the validFrom and validUntil dates */
    DiscountCodeState["NotValid"] = "NotValid";
})(DiscountCodeState || (DiscountCodeState = {}));
export var InventoryMode;
(function (InventoryMode) {
    /**
     * Adding items to cart and ordering is independent of inventory. No inventory checks or modifications.
     * This is the default mode for a new cart.
     */
    InventoryMode["None"] = "None";
    /**
     * Creating an order will fail with an OutOfStock error if an unavailable line item exists. Line items in the cart
     * are only reserved for the duration of the ordering transaction.
     */
    InventoryMode["ReserveOnOrder"] = "ReserveOnOrder";
    /**
     * Orders are tracked on inventory. That means, ordering a LineItem will decrement the available quantity on the
     * respective InventoryEntry. Creating an order will succeed even if the line item’s available quantity is zero or
     * negative. But creating an order will fail with an OutOfStock error if no matching inventory entry exists for a
     * line item.
     */
    InventoryMode["TrackOnly"] = "TrackOnly";
})(InventoryMode || (InventoryMode = {}));
export var LineItemMode;
(function (LineItemMode) {
    /**
     * The line item was added automatically, because a discount has added a free gift to the cart.
     * The quantity can not be increased, and it won’t be merged when the same product variant is added.
     * If the gift is removed, an entry is added to the "refusedGifts" array and the discount won’t be applied again
     * to the cart. The price can not be changed externally.
     * All other updates, such as the ones related to custom fields, can be used.
     */
    LineItemMode["GiftLineItem"] = "GiftLineItem";
    /**
     * The line item was added during cart creation or with the update action addLineItem. Its quantity can be
     * changed without restrictions.
     */
    LineItemMode["Standard"] = "Standard";
})(LineItemMode || (LineItemMode = {}));
export var LineItemPriceMode;
(function (LineItemPriceMode) {
    /** The line item price was set externally. Cart discounts can apply to line items with this price mode. All update actions that change the quantity of a line item with this price mode require the externalPrice field to be given. */
    LineItemPriceMode["ExternalPrice"] = "ExternalPrice";
    /** The line item price with the total was set externally. */
    LineItemPriceMode["ExternalTotal"] = "ExternalTotal";
    /** The price is selected form the product variant. This is the default mode. */
    LineItemPriceMode["Platform"] = "Platform";
})(LineItemPriceMode || (LineItemPriceMode = {}));
export var MyQuoteState;
(function (MyQuoteState) {
    MyQuoteState["Accepted"] = "Accepted";
    MyQuoteState["Declined"] = "Declined";
})(MyQuoteState || (MyQuoteState = {}));
export var OrderSearchStatus;
(function (OrderSearchStatus) {
    OrderSearchStatus["Activated"] = "Activated";
    OrderSearchStatus["Deactivated"] = "Deactivated";
})(OrderSearchStatus || (OrderSearchStatus = {}));
export var OrderState;
(function (OrderState) {
    OrderState["Cancelled"] = "Cancelled";
    OrderState["Complete"] = "Complete";
    OrderState["Confirmed"] = "Confirmed";
    OrderState["Open"] = "Open";
})(OrderState || (OrderState = {}));
export var PaymentState;
(function (PaymentState) {
    PaymentState["BalanceDue"] = "BalanceDue";
    PaymentState["CreditOwed"] = "CreditOwed";
    PaymentState["Failed"] = "Failed";
    PaymentState["Paid"] = "Paid";
    PaymentState["Pending"] = "Pending";
})(PaymentState || (PaymentState = {}));
export var Permission;
(function (Permission) {
    Permission["AcceptMyQuotes"] = "AcceptMyQuotes";
    Permission["AcceptOthersQuotes"] = "AcceptOthersQuotes";
    Permission["AddChildUnits"] = "AddChildUnits";
    Permission["CreateApprovalRules"] = "CreateApprovalRules";
    Permission["CreateMyCarts"] = "CreateMyCarts";
    Permission["CreateMyOrdersFromMyCarts"] = "CreateMyOrdersFromMyCarts";
    Permission["CreateMyOrdersFromMyQuotes"] = "CreateMyOrdersFromMyQuotes";
    Permission["CreateMyQuoteRequestsFromMyCarts"] = "CreateMyQuoteRequestsFromMyCarts";
    Permission["CreateOrdersFromOthersCarts"] = "CreateOrdersFromOthersCarts";
    Permission["CreateOrdersFromOthersQuotes"] = "CreateOrdersFromOthersQuotes";
    Permission["CreateOthersCarts"] = "CreateOthersCarts";
    Permission["CreateQuoteRequestsFromOthersCarts"] = "CreateQuoteRequestsFromOthersCarts";
    Permission["DeclineMyQuotes"] = "DeclineMyQuotes";
    Permission["DeclineOthersQuotes"] = "DeclineOthersQuotes";
    Permission["DeleteMyCarts"] = "DeleteMyCarts";
    Permission["DeleteOthersCarts"] = "DeleteOthersCarts";
    Permission["ReassignMyQuotes"] = "ReassignMyQuotes";
    Permission["ReassignOthersQuotes"] = "ReassignOthersQuotes";
    Permission["RenegotiateMyQuotes"] = "RenegotiateMyQuotes";
    Permission["RenegotiateOthersQuotes"] = "RenegotiateOthersQuotes";
    Permission["UpdateApprovalFlows"] = "UpdateApprovalFlows";
    Permission["UpdateApprovalRules"] = "UpdateApprovalRules";
    Permission["UpdateAssociates"] = "UpdateAssociates";
    Permission["UpdateBusinessUnitDetails"] = "UpdateBusinessUnitDetails";
    Permission["UpdateMyCarts"] = "UpdateMyCarts";
    Permission["UpdateMyOrders"] = "UpdateMyOrders";
    Permission["UpdateMyQuoteRequests"] = "UpdateMyQuoteRequests";
    Permission["UpdateOthersCarts"] = "UpdateOthersCarts";
    Permission["UpdateOthersOrders"] = "UpdateOthersOrders";
    Permission["UpdateOthersQuoteRequests"] = "UpdateOthersQuoteRequests";
    Permission["UpdateParentUnit"] = "UpdateParentUnit";
    Permission["ViewMyCarts"] = "ViewMyCarts";
    Permission["ViewMyOrders"] = "ViewMyOrders";
    Permission["ViewMyQuoteRequests"] = "ViewMyQuoteRequests";
    Permission["ViewMyQuotes"] = "ViewMyQuotes";
    Permission["ViewOthersCarts"] = "ViewOthersCarts";
    Permission["ViewOthersOrders"] = "ViewOthersOrders";
    Permission["ViewOthersQuoteRequests"] = "ViewOthersQuoteRequests";
    Permission["ViewOthersQuotes"] = "ViewOthersQuotes";
})(Permission || (Permission = {}));
/**
 * This mode determines which type of Prices the system uses for
 * Product Price Selection as well as for LineItem Price selection
 */
export var PriceMode;
(function (PriceMode) {
    /** The system looks up prices from the `prices` field of the ProductVariant inside a Product. */
    PriceMode["Embedded"] = "Embedded";
    /** The system looks up prices from Standalone Prices, stored separately from Products. */
    PriceMode["Standalone"] = "Standalone";
})(PriceMode || (PriceMode = {}));
export var ProductSelectionMode;
(function (ProductSelectionMode) {
    /** Mode of Product Selection used to include a specific list of individual Products */
    ProductSelectionMode["Individual"] = "Individual";
    /** Mode of Product Selection used to exclude a specific list of individual Products */
    ProductSelectionMode["IndividualExclusion"] = "IndividualExclusion";
})(ProductSelectionMode || (ProductSelectionMode = {}));
export var PublishScope;
(function (PublishScope) {
    /** Publishes the complete staged projection */
    PublishScope["All"] = "All";
    /** Publishes only prices on the staged projection */
    PublishScope["Prices"] = "Prices";
})(PublishScope || (PublishScope = {}));
export var QuoteRequestState;
(function (QuoteRequestState) {
    QuoteRequestState["Accepted"] = "Accepted";
    QuoteRequestState["Cancelled"] = "Cancelled";
    QuoteRequestState["Closed"] = "Closed";
    QuoteRequestState["Rejected"] = "Rejected";
    QuoteRequestState["Submitted"] = "Submitted";
    QuoteRequestState["UnderReview"] = "UnderReview";
})(QuoteRequestState || (QuoteRequestState = {}));
export var QuoteState;
(function (QuoteState) {
    QuoteState["Accepted"] = "Accepted";
    QuoteState["Declined"] = "Declined";
    QuoteState["DeclinedForRenegotiation"] = "DeclinedForRenegotiation";
    QuoteState["Pending"] = "Pending";
    QuoteState["RenegotiationAddressed"] = "RenegotiationAddressed";
    QuoteState["Withdrawn"] = "Withdrawn";
})(QuoteState || (QuoteState = {}));
export var ReturnPaymentState;
(function (ReturnPaymentState) {
    ReturnPaymentState["Initial"] = "Initial";
    ReturnPaymentState["NonRefundable"] = "NonRefundable";
    ReturnPaymentState["NotRefunded"] = "NotRefunded";
    ReturnPaymentState["Refunded"] = "Refunded";
})(ReturnPaymentState || (ReturnPaymentState = {}));
export var ReturnShipmentState;
(function (ReturnShipmentState) {
    ReturnShipmentState["Advised"] = "Advised";
    ReturnShipmentState["BackInStock"] = "BackInStock";
    ReturnShipmentState["Returned"] = "Returned";
    ReturnShipmentState["Unusable"] = "Unusable";
})(ReturnShipmentState || (ReturnShipmentState = {}));
export var RoundingMode;
(function (RoundingMode) {
    /** [Round half down](https://en.wikipedia.org/wiki/Rounding#Round_half_down) */
    RoundingMode["HalfDown"] = "HalfDown";
    /** [Round half to even](https://en.wikipedia.org/wiki/Rounding#Round_half_to_even). Default rounding mode as used in IEEE 754 computing functions and operators. */
    RoundingMode["HalfEven"] = "HalfEven";
    /** [Round half up](https://en.wikipedia.org/wiki/Rounding#Round_half_up) */
    RoundingMode["HalfUp"] = "HalfUp";
})(RoundingMode || (RoundingMode = {}));
export var SearchIndexingStatus;
(function (SearchIndexingStatus) {
    SearchIndexingStatus["Activated"] = "Activated";
    SearchIndexingStatus["Deactivated"] = "Deactivated";
    SearchIndexingStatus["Indexing"] = "Indexing";
})(SearchIndexingStatus || (SearchIndexingStatus = {}));
/** In order to decide which of the matching items will actually be discounted */
export var SelectionMode;
(function (SelectionMode) {
    SelectionMode["Cheapest"] = "Cheapest";
    SelectionMode["MostExpensive"] = "MostExpensive";
})(SelectionMode || (SelectionMode = {}));
export var ShipmentState;
(function (ShipmentState) {
    ShipmentState["Backorder"] = "Backorder";
    ShipmentState["Canceled"] = "Canceled";
    ShipmentState["Delayed"] = "Delayed";
    ShipmentState["Delivered"] = "Delivered";
    ShipmentState["Partial"] = "Partial";
    ShipmentState["Pending"] = "Pending";
    ShipmentState["Ready"] = "Ready";
    ShipmentState["Shipped"] = "Shipped";
})(ShipmentState || (ShipmentState = {}));
export var ShippingMethodState;
(function (ShippingMethodState) {
    /** The ShippingMethod predicate does not match the cart. Ordering this cart will fail with error ShippingMethodDoesNotMatchCart */
    ShippingMethodState["DoesNotMatchCart"] = "DoesNotMatchCart";
    /** Either there is no predicate defined for the ShippingMethod or the given predicate matches the cart */
    ShippingMethodState["MatchesCart"] = "MatchesCart";
})(ShippingMethodState || (ShippingMethodState = {}));
export var ShippingMode;
(function (ShippingMode) {
    /** Allows multiple shipping methods for the cart with their respective shipping addresses */
    ShippingMode["Multiple"] = "Multiple";
    /** Allows only one shipping method and shipping address for the cart */
    ShippingMode["Single"] = "Single";
})(ShippingMode || (ShippingMode = {}));
/** Describes how this discount interacts with other discounts */
export var StackingMode;
(function (StackingMode) {
    /** Default. Continue applying other matching discounts after applying this one. */
    StackingMode["Stacking"] = "Stacking";
    /** Don’t apply any more matching discounts after this one. */
    StackingMode["StopAfterThisDiscount"] = "StopAfterThisDiscount";
})(StackingMode || (StackingMode = {}));
export var StagedQuoteState;
(function (StagedQuoteState) {
    StagedQuoteState["Closed"] = "Closed";
    StagedQuoteState["InProgress"] = "InProgress";
    StagedQuoteState["Sent"] = "Sent";
})(StagedQuoteState || (StagedQuoteState = {}));
export var StateRole;
(function (StateRole) {
    StateRole["Return"] = "Return";
    StateRole["ReviewIncludedInStatistics"] = "ReviewIncludedInStatistics";
})(StateRole || (StateRole = {}));
export var StateType;
(function (StateType) {
    StateType["LineItemState"] = "LineItemState";
    StateType["OrderState"] = "OrderState";
    StateType["PaymentState"] = "PaymentState";
    StateType["ProductState"] = "ProductState";
    StateType["QuoteRequestState"] = "QuoteRequestState";
    StateType["QuoteState"] = "QuoteState";
    StateType["ReviewState"] = "ReviewState";
    StateType["StagedQuoteState"] = "StagedQuoteState";
})(StateType || (StateType = {}));
export var SubscriptionHealthStatus;
(function (SubscriptionHealthStatus) {
    SubscriptionHealthStatus["ConfigurationError"] = "ConfigurationError";
    SubscriptionHealthStatus["ConfigurationErrorDeliveryStopped"] = "ConfigurationErrorDeliveryStopped";
    SubscriptionHealthStatus["Healthy"] = "Healthy";
    SubscriptionHealthStatus["ManuallySuspended"] = "ManuallySuspended";
    SubscriptionHealthStatus["TemporaryError"] = "TemporaryError";
})(SubscriptionHealthStatus || (SubscriptionHealthStatus = {}));
export var TaxCalculationMode;
(function (TaxCalculationMode) {
    /**
     * Default. This calculation mode calculates the taxes after the unit price is multiplied with the quantity.
     * E.g. `($1.08 * 3 = $3.24) * 1.19 = $3.8556 -> $3.86 rounded`
     */
    TaxCalculationMode["LineItemLevel"] = "LineItemLevel";
    /**
     * This calculation mode calculates the taxes on the unit price before multiplying with the quantity.
     * E.g. `($1.08 * 1.19 = $1.2852 -> $1.29 rounded) * 3 = $3.87`
     */
    TaxCalculationMode["UnitPriceLevel"] = "UnitPriceLevel";
})(TaxCalculationMode || (TaxCalculationMode = {}));
export var TaxMode;
(function (TaxMode) {
    /** No taxes are added to the cart. */
    TaxMode["Disabled"] = "Disabled";
    /**
     * The tax rates are set externally per ExternalTaxRateDraft. A cart with this tax mode can only be ordered if all
     * line items, all custom line items and the shipping method have an external tax rate set. The totalNet and
     * totalGross as well as the taxPortions fields are calculated according to the taxRoundingMode.
     */
    TaxMode["External"] = "External";
    /**
     * The tax amounts and the tax rates as well as the tax portions are set externally per ExternalTaxAmountDraft.
     * A cart with this tax mode can only be ordered if the cart itself and all line items, all custom line items and
     * the shipping method have an external tax amount and rate set
     */
    TaxMode["ExternalAmount"] = "ExternalAmount";
    /**
     * The tax rates are selected from the TaxCategories based on the cart shipping address.
     * The totalNet and totalGross as well as the taxPortions fields are calculated according to the
     * taxRoundingMode.
     */
    TaxMode["Platform"] = "Platform";
})(TaxMode || (TaxMode = {}));
/** UI hint telling what kind of edit control should be displayed for a text attribute. */
export var TextInputHint;
(function (TextInputHint) {
    TextInputHint["MultiLine"] = "MultiLine";
    TextInputHint["SingleLine"] = "SingleLine";
})(TextInputHint || (TextInputHint = {}));
export var TransactionState;
(function (TransactionState) {
    TransactionState["Failure"] = "Failure";
    TransactionState["Initial"] = "Initial";
    TransactionState["Pending"] = "Pending";
    TransactionState["Success"] = "Success";
})(TransactionState || (TransactionState = {}));
export var TransactionType;
(function (TransactionType) {
    TransactionType["Authorization"] = "Authorization";
    TransactionType["CancelAuthorization"] = "CancelAuthorization";
    TransactionType["Charge"] = "Charge";
    TransactionType["Chargeback"] = "Chargeback";
    TransactionType["Refund"] = "Refund";
})(TransactionType || (TransactionType = {}));
export const RawCustomFieldFragmentDoc = gql `
    fragment RawCustomField on RawCustomField {
  value
  name
}
    `;
export const CategoryFragmentDoc = gql `
    fragment Category on Category {
  id
  key
  lastModifiedAt
  parent {
    id
  }
  externalId
  custom {
    customFieldsRaw(includeNames: ["path"]) {
      ...RawCustomField
    }
  }
}
    ${RawCustomFieldFragmentDoc}`;
export const ProductAttributesRawFragmentDoc = gql `
    fragment ProductAttributesRaw on RawProductAttribute {
  value
  name
}
    `;
export const ProductFragmentDoc = gql `
    fragment Product on Product {
  id
  lastModifiedAt
  masterData {
    current {
      masterVariant {
        images {
          url
          label
        }
        attributesRaw(includeNames: ["path"]) {
          ...ProductAttributesRaw
        }
      }
    }
  }
}
    ${ProductAttributesRawFragmentDoc}`;
export const CategoriesTotalDocument = gql `
    query categoriesTotal {
  categories(limit: 1) {
    total
  }
}
    `;
export const CategoriesDocument = gql `
    query categories($limit: Int, $offset: Int) {
  categories(limit: $limit, offset: $offset) {
    results {
      ...Category
    }
  }
}
    ${CategoryFragmentDoc}`;
export const CategoryDocument = gql `
    query category($id: String!, $locale: Locale!) {
  category(id: $id) {
    description(locale: $locale)
    custom {
      customFieldsRaw(includeNames: ["path"]) {
        ...RawCustomField
      }
    }
  }
}
    ${RawCustomFieldFragmentDoc}`;
export const ProductsTotalDocument = gql `
    query productsTotal {
  products(limit: 1, where: "masterData(published = true)") {
    total
  }
}
    `;
export const ProductDocument = gql `
    query product($id: String!, $locale1: Locale!, $locale2: Locale!) {
  product(id: $id) {
    id
    lastModifiedAt
    masterData {
      published
      current {
        name(locale: $locale1)
        slug(locale: $locale1)
        description(locale: $locale2)
        categories {
          id
          orderHint
          key
          custom {
            customFieldsRaw {
              ...RawCustomField
            }
          }
        }
        masterVariant {
          images {
            url
            label
          }
          attributesRaw {
            ...ProductAttributesRaw
          }
        }
      }
    }
  }
}
    ${RawCustomFieldFragmentDoc}
${ProductAttributesRawFragmentDoc}`;
export const ProductsDocument = gql `
    query products($limit: Int, $offset: Int) {
  products(limit: $limit, offset: $offset, where: "masterData(published = true)") {
    total
    results {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}`;
const defaultWrapper = (action, _operationName, _operationType) => action();
export function getSdk(client, withWrapper = defaultWrapper) {
    return {
        categoriesTotal(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.request(CategoriesTotalDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'categoriesTotal', 'query');
        },
        categories(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.request(CategoriesDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'categories', 'query');
        },
        category(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.request(CategoryDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'category', 'query');
        },
        productsTotal(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.request(ProductsTotalDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'productsTotal', 'query');
        },
        product(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.request(ProductDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'product', 'query');
        },
        products(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.request(ProductsDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'products', 'query');
        }
    };
}
