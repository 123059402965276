import dayjs from "dayjs";
import fs from "fs";
import path from "path";
import { create } from "xmlbuilder2";
import { createGzip } from "zlib";
import { getDomain, typedBoolean } from "../helper";
const siteUrl = getDomain();
export const getPageLink = (path) => `${siteUrl}/${path === null || path === void 0 ? void 0 : path.split("/").filter(typedBoolean).join("/")}`;
export const formatDate = (dateStr) => (dateStr ? dayjs(dateStr).format("YYYY-MM-DD") : "");
const xmlns = "http://www.sitemaps.org/schemas/sitemap/0.9";
const xmlnsNews = "http://www.google.com/schemas/sitemap-news/0.9";
const xmlnsHtml = "http://www.w3.org/1999/xhtml";
const xmlnsMobile = "http://www.google.com/schemas/sitemap-mobile/1.0";
const xmlnsImage = "http://www.google.com/schemas/sitemap-image/1.1";
const xmlnsVideo = "http://www.google.com/schemas/sitemap-video/1.1";
const writeFileByPath = (data, filePath, cb) => {
    fs.writeFileSync(path.resolve() + filePath, data);
    cb && cb();
};
const writeGunZipFile = (filePath) => {
    const _filePath = path.resolve() + filePath;
    const gzFilePath = path.resolve() + `${filePath}.gz`;
    const gzip = createGzip();
    const source = fs.createReadStream(_filePath);
    const destination = fs.createWriteStream(gzFilePath);
    source.pipe(gzip).pipe(destination);
};
const createSitemap = () => {
    const root = create({ version: "1.0", encoding: "UTF-8" })
        .ele("sitemapindex", { xmlns })
        .ele("sitemap")
        .ele("loc")
        .txt(`${siteUrl}/sitemap-products.xml.gz`)
        .up()
        .up()
        .ele("sitemap")
        .ele("loc")
        .txt(`${siteUrl}/sitemap-categories.xml.gz`)
        .up()
        .up()
        .ele("sitemap")
        .ele("loc")
        .txt(`${siteUrl}/sitemap-categories-soft.xml.gz`)
        .up()
        .up()
        .ele("sitemap")
        .ele("loc")
        .txt(`${siteUrl}/sitemap-marketing.xml.gz`)
        .up()
        .up();
    const xml = root.end({ prettyPrint: true });
    writeFileByPath(xml, "/public/sitemap.xml");
};
const createSitemapDetail = (sitemapPages) => {
    sitemapPages.forEach(({ name, data }) => {
        const root = create({ version: "1.0", encoding: "UTF-8" }).ele("urlset", {
            xmlns,
            "xmlns:news": xmlnsNews,
            "xmlns:xhtml": xmlnsHtml,
            "xmlns:mobile": xmlnsMobile,
            "xmlns:image": xmlnsImage,
            "xmlns:video": xmlnsVideo,
        });
        data.forEach((page) => {
            const item = root
                .ele("url")
                .ele("loc")
                .txt(page.url.toLowerCase())
                .up()
                .ele("changefreq")
                .txt(page.changefreq)
                .up()
                .ele("priority")
                .txt(String(page.priority))
                .up();
            page.lastmod && item.ele("lastmod").txt(page.lastmod).up();
            page.image && item.ele("image:image").ele("image:loc").txt(page.image).up();
        });
        const xml = root.end({ prettyPrint: true });
        const filePath = `/public/${name}.xml`;
        writeFileByPath(xml, filePath, () => writeGunZipFile(filePath));
    });
};
export const generateSitemap = (sitemapPages) => {
    createSitemap();
    createSitemapDetail(sitemapPages);
};
